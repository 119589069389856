// eslint-disable-next-line
import { UserLayout, BasicLayout, RouteView, BlankLayout, PageView } from '@/layouts'

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/wms/business/in',
    children: [
      {
        path: '/wms',
        name: 'wms',
        component: PageView,
        meta: { title: '仓储部', icon: 'icon-wms' },
        children: [
          {
            path: '/wms/business',
            component: RouteView,
            meta: { title: '仓库业务' },
            redirect: '/wms/business/in',
            children: [
              {
                path: '/wms/business/in',
                name: 'In',
                component: () => import('@/views/wms/business/CumsInList'),
                meta: { title: '入库管理' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/business/in/:id',
                    name: 'InCUR',
                    component: () => import('@/views/wms/business/CumsInCUR'),
                    meta: { title: '新增入库', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/business/out',
                name: 'Out',
                component: () => import('@/views/wms/business/CumsOutList'),
                meta: { title: '出库管理' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/business/out/:id',
                    name: 'OutCUR',
                    component: () => import('@/views/wms/business/CumsOutCUR'),
                    meta: { title: '新增出库', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/business/cms_biz',
                name: 'WmsCmsBiz',
                component: () => import('@/views/wms/business/WmsCmsBiz'),
                meta: { title: '查验业务' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/business/cms_biz/:id',
                    name: 'WmsCmsBizEdit',
                    component: () => import('@/views/wms/business/WmsCmsBizEdit'),
                    meta: { title: '查验业务编辑', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/business/in_statistic',
                name: 'InStatistic',
                component: () => import('@/views/wms/business/InStatistic'),
                meta: { title: '入库统计表' }
              },
              {
                path: '/wms/business/out_statistic',
                name: 'OutStatistic',
                component: () => import('@/views/wms/business/OutStatistic'),
                meta: { title: '出库统计表' }
              },
              {
                path: '/wms/business/stock_statistic',
                name: 'StockStatistic',
                component: () => import('@/views/wms/business/StockStatistic'),
                meta: { title: '库存汇总' }
              }
            ]
          },
          {
            path: '/wms/sale',
            component: RouteView,
            meta: { title: '进销存管理' },
            redirect: '/wms/sale/purchase',
            children: [
              {
                path: '/wms/sale/purchase',
                name: 'Purchase',
                component: () => import('@/views/wms/sale/Purchase'),
                meta: { title: '进货' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/sale/purchase/:id',
                    name: 'PurchaseCUR',
                    component: () => import('@/views/wms/sale/PurchaseCUR'),
                    meta: { title: '新增进货信息', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/sale/sell',
                name: 'Sell',
                component: () => import('@/views/wms/sale/Sell'),
                meta: { title: '出货' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/sale/sell/:id',
                    name: 'SellCUR',
                    component: () => import('@/views/wms/sale/SellCUR'),
                    meta: { title: '新增出货信息', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/sale/agent',
                name: 'Agent',
                component: () => import('@/views/wms/sale/Agent'),
                meta: { title: '代销' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/sale/agent/:id',
                    name: 'AgentCUR',
                    component: () => import('@/views/wms/sale/AgentCUR'),
                    meta: { title: '新增代销信息', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/sale/stock',
                name: 'Stock',
                component: () => import('@/views/wms/sale/Stock'),
                meta: { title: '库存', keepAlive: true },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/sale/stock/detail',
                    name: 'StockDetail',
                    hidden: true,
                    component: () => import('@/views/wms/sale/StockDetail'),
                    meta: { title: '库存详细', subPage: true }
                  }
                ]
              },
              {
                path: '/wms/sale/statics',
                name: 'Statics',
                component: () => import('@/views/wms/sale/Statics'),
                meta: { title: '统计分析', keepAlive: true }
              },
              {
                path: '/wms/sale/buyer',
                name: 'Buyer',
                component: () => import('@/views/wms/sale/Buyer'),
                meta: { title: '购买单位' }
              },
              {
                path: '/wms/sale/sale_goods_name',
                name: 'SaleGoodsName',
                component: () => import('@/views/wms/sale/GoodsName'),
                meta: { title: '货物名称' }
              },
              {
                path: '/wms/meta/sale_fixedfee',
                name: 'SaleFixedFee',
                component: () => import('@/views/wms/sale/FixedFee'),
                meta: { title: '固定费用' }
              },
              {
                path: '/wms/meta/pay_target',
                name: 'PayTarget',
                component: () => import('@/views/wms/sale/PayTarget'),
                meta: { title: '付款对象' }
              },
              {
                path: '/wms/sale/finance',
                name: 'WmsSaleFinance',
                component: RouteView,
                meta: { title: '财务中心' },
                children: [
                  {
                    path: '/wms/sale/finance/charge_list',
                    name: 'WmsSaleChargeData',
                    component: () => import('@/views/wms/sale/finance/WmsSaleChargeData'),
                    meta: { title: '应收对账单' }
                  },
                  {
                    path: '/wms/sale/finance/pay_list',
                    name: 'WmsSalePayData',
                    component: () => import('@/views/wms/sale/finance/WmsSalePayData'),
                    meta: { title: '应付对账单' }
                  },
                  {
                    path: '/wms/sale/finance/wms_sale_profit',
                    name: 'WmsSaleProfitDetail',
                    component: () => import('@/views/wms/sale/finance/WmsSaleProfitDetail'),
                    meta: { title: '利润明细表' }
                  }
                ]
              }
            ]
          },
          {
            path: '/wms/data',
            component: RouteView,
            meta: { title: '仓库资料' },
            redirect: '/wms/data/warehouse',
            children: [
              {
                path: '/wms/data/warehouse',
                name: 'Warehouse',
                component: () => import('@/views/wms/data/Warehouse'),
                meta: { title: '仓库信息' }
              },
              {
                path: '/wms/data/operator',
                name: 'Operator',
                component: () => import('@/views/wms/data/Operator'),
                meta: { title: '作业人员' }
              },
              {
                path: '/wms/data/wrapper',
                name: 'Wrapper',
                component: () => import('@/views/wms/data/Wrapper'),
                meta: { title: '包装形式' }
              },
              {
                path: '/wms/data/goods_name',
                name: 'GoodsName',
                component: () => import('@/views/wms/data/GoodsName'),
                meta: { title: '仓库货名' }
              },
              {
                path: '/wms/data/breakage',
                name: 'Breakage',
                component: () => import('@/views/wms/data/Breakage'),
                meta: { title: '破损情况' }
              },
              {
                path: '/wms/data/enter_tips',
                name: 'EnterTips',
                component: () => import('@/views/wms/data/EnterTips'),
                meta: { title: '进仓提示' }
              },
              {
                path: '/wms/data/vehicle_type',
                name: 'VehicleType',
                component: () => import('@/views/wms/data/VehicleType'),
                meta: { title: '车型信息' }
              },
              {
                path: '/wms/data/in_charge_rule',
                name: 'InChargeRule',
                component: () => import('@/views/wms/data/InChargeRule'),
                meta: { title: '入库费用规则' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/data/in_charge_rule/:id',
                    name: 'InChargeRuleCur',
                    component: () => import('@/views/wms/data/InChargeRuleCur'),
                    meta: { title: '新增入库费用规则', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/data/out_charge_rule',
                name: 'OutChargeRule',
                component: () => import('@/views/wms/data/OutChargeRule'),
                meta: { title: '出库费用规则' }
              },
              {
                path: '/wms/data/operation_type',
                name: 'CmsOperationType',
                component: () => import('@/views/wms/data/CmsOperationType'),
                meta: { title: '操作内容' }
              }
            ]
          },
          {
            path: '/wms/meta',
            component: RouteView,
            meta: { title: '基础信息' },
            redirect: '/wms/meta/factory',
            children: [
              {
                path: '/wms/meta/biz_type',
                name: 'WmsBizType',
                component: () => import('@/views/wms/meta/BizType'),
                meta: { title: '业务类型' }
              },
              {
                path: '/wms/meta/factory',
                name: 'Factory',
                component: () => import('@/views/wms/meta/Factory'),
                meta: { title: '工厂信息' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/wms/meta/factory/:id',
                    name: 'FactoryCur',
                    hidden: true,
                    component: () => import('@/views/wms/meta/FactoryCUR'),
                    meta: { title: '新增工厂信息', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/wms/meta/wharf',
                name: 'Wharf',
                component: () => import('@/views/wms/meta/Wharf'),
                meta: { title: '港区' }
              },
              {
                path: '/wms/meta/district',
                name: 'District',
                component: () => import('@/views/wms/meta/District'),
                meta: { title: '地区' }
              },
              {
                path: '/wms/meta/location',
                name: 'Location',
                component: () => import('@/views/wms/meta/Location'),
                meta: { title: '地点' }
              },
              {
                path: '/wms/meta/fleet',
                name: 'Fleet',
                component: () => import('@/views/wms/meta/Fleet'),
                meta: { title: '车队' }
              },
              {
                path: '/wms/meta/driver',
                name: 'Driver',
                component: () => import('@/views/wms/meta/Driver'),
                meta: { title: '司机' }
              },
              {
                path: '/wms/meta/vehicle',
                name: 'Vehicle',
                component: () => import('@/views/wms/meta/Vehicle'),
                meta: { title: '车辆' }
              },
              {
                path: '/wms/meta/payTarget',
                name: 'PayTarget',
                component: () => import('@/views/wms/meta/PayTarget'),
                meta: { title: '成本支付单位' }
              }
            ]
          },
          {
            path: '/wms/finance',
            name: 'WmsFinance',
            component: RouteView,
            meta: { title: '财务中心' },
            children: [
              {
                path: '/wms/finance/charge_list',
                name: 'WmsChargeData',
                component: () => import('@/views/wms/finance/WmsChargeData'),
                meta: { title: '应收对账单' }
              },
              {
                path: '/wms/finance/pay_list',
                name: 'WmsPayData',
                component: () => import('@/views/wms/finance/WmsPayData'),
                meta: { title: '应付对账单' }
              },
              {
                path: '/wms/finance/wms_sale_profit',
                name: 'WmsProfitDetail',
                component: () => import('@/views/wms/finance/WmsProfitDetail'),
                meta: { title: '利润明细表' }
              }
            ]
          }
        ]
      },
      {
        path: '/itms',
        name: 'itms',
        component: PageView,
        meta: { title: '国贸部', icon: 'icon-itms' },
        children: [
          {
            path: '/itms/meta',
            name: 'ItmsMeta',
            component: RouteView,
            meta: { title: '基础信息' },
            children: [
              {
                path: '/itms/meta/door_point',
                name: 'DoorPoint',
                component: () => import('@/views/itms/meta/DoorPoint'),
                meta: { title: '门点' }
              },
              {
                path: '/itms/meta/agent',
                name: 'Agent',
                component: () => import('@/views/itms/meta/Agent'),
                meta: { title: '订舱代理' }
              },
              {
                path: '/itms/meta/agent_aboard',
                name: 'AgentAboard',
                component: () => import('@/views/itms/meta/AgentAboard'),
                meta: { title: '国外代理' }
              },
              {
                path: '/itms/meta/custom_com',
                name: 'CustomCom',
                component: () => import('@/views/itms/meta/CustomCom'),
                meta: { title: '报关行' }
              },
              {
                path: '/itms/meta/ship_company',
                name: 'ShipCompany',
                component: () => import('@/views/itms/meta/ShipCompany'),
                meta: { title: '船公司' }
              },
              {
                path: '/itms/meta/ship',
                name: 'Ship',
                component: () => import('@/views/itms/meta/Ship'),
                meta: { title: '船名' }
              },
              {
                path: '/itms/meta/dock',
                name: 'Dock',
                component: () => import('@/views/itms/meta/Dock'),
                meta: { title: '码头' }
              },
              {
                path: '/itms/meta/harbor_start',
                name: 'HarborStart',
                component: () => import('@/views/itms/meta/HarborStart'),
                meta: { title: '起运港' }
              },
              {
                path: '/itms/meta/harbor_dest',
                name: 'HarborDest',
                component: () => import('@/views/itms/meta/HarborDest'),
                meta: { title: '目的港' }
              },
              {
                path: '/itms/meta/trade_term',
                name: 'TradeTerm',
                component: () => import('@/views/itms/meta/TradeTerm'),
                meta: { title: '贸易条款' }
              },
              {
                path: '/itms/meta/trans_term',
                name: 'TransTerm',
                component: () => import('@/views/itms/meta/TransTerm'),
                meta: { title: '运输条款' }
              },
              {
                path: '/itms/meta/itms_vehicle',
                name: 'ItmsVehicle',
                component: () => import('@/views/itms/meta/ItmsVehicle'),
                meta: { title: '车队' }
              },
              {
                path: '/itms/meta/store_in',
                name: 'StoreIn',
                component: () => import('@/views/itms/meta/StoreIn'),
                meta: { title: '区内仓库' }
              },
              {
                path: '/itms/meta/store_out',
                name: 'StoreOut',
                component: () => import('@/views/itms/meta/StoreOut'),
                meta: { title: '区外仓库' }
              },
              {
                path: '/itms/meta/itms_fixfee',
                name: 'ItmsFixFee',
                component: () => import('@/views/itms/meta/ItmsFixFee'),
                meta: { title: '固定费用' }
              },
              {
                path: '/itms/meta/pay_target',
                name: 'payTarget',
                component: () => import('@/views/itms/meta/PayTarget'),
                meta: { title: '付款对象' }
              }
            ]
          },
          {
            path: '/itms/order',
            name: 'ItmsOrder',
            component: RouteView,
            meta: { title: '订单管理' },
            children: [
              {
                path: '/itms/order/sea_order',
                name: 'SeaOrder',
                component: () => import('@/views/itms/order/SeaOrder'),
                meta: { title: '海运空运' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/itms/order/sea_order/:id',
                    name: 'SeaOrderCUR',
                    component: () => import('@/views/itms/order/SeaOrderCUR'),
                    meta: { title: '新增订单', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/itms/order/logistics',
                name: 'Logistics',
                component: () => import('@/views/itms/order/Logistics'),
                meta: { title: '物流园报关' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/itms/order/logistics/:id',
                    name: 'LogisticsCUR',
                    component: () => import('@/views/itms/order/LogisticsCUR'),
                    meta: { title: '新增物流园报关订单', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/itms/order/land',
                name: 'Land',
                component: () => import('@/views/itms/order/Land'),
                meta: { title: '陆运' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/itms/order/land/:id',
                    name: 'LandCUR',
                    component: () => import('@/views/itms/order/LandCUR'),
                    meta: { title: '新增陆运订单', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/itms/order/railway',
                name: 'Railway',
                component: () => import('@/views/itms/order/Railway'),
                meta: { title: '铁路运输' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/itms/order/railway_order/:id',
                    name: 'RailwayCUR',
                    component: () => import('@/views/itms/order/RailwayCUR'),
                    meta: { title: '新增铁路运输订单', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/itms/order/domestic_trade',
                name: 'DomesticTrade',
                component: () => import('@/views/itms/order/DomesticTrade'),
                meta: { title: '内贸水运' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/itms/order/domestic_trade/:id',
                    name: 'DomesticTradeCUR',
                    component: () => import('@/views/itms/order/DomesticTradeCUR'),
                    meta: { title: '新增内贸水运订单', subPage: true, hidden: true }
                  }
                ]
              }
            ]
          },
          {
            path: '/itms/finance',
            name: 'ItmsFinance',
            component: RouteView,
            meta: { title: '财务中心' },
            children: [
              {
                path: '/itms/finance/charge_list',
                name: 'ItmsChargeData',
                component: () => import('@/views/itms/finance/ItmsChargeData'),
                meta: { title: '应收对账单' }
              },
              {
                path: '/itms/finance/pay_list',
                name: 'ItmsPayData',
                component: () => import('@/views/itms/finance/ItmsPayData'),
                meta: { title: '应付对账单' }
              },
              {
                path: '/itms/finance/itms_profit',
                name: 'ItmsProfitDetail',
                component: () => import('@/views/itms/finance/ItmsProfitDetail'),
                meta: { title: '利润明细表' }
              }
            ]
          }
        ]
      },
      {
        path: '/fms',
        name: 'fms',
        component: PageView,
        meta: { title: '财商部', icon: 'icon-fqms' },
        children: [
          {
            path: '/fms/biz_audit',
            name: 'BizAudit',
            component: () => import('@/views/fms/BizAudit'),
            meta: { title: '商务审核' }
          },
          {
            path: '/fms/meta',
            component: RouteView,
            meta: { title: '基础信息' },
            children: [
              {
                path: '/fms/meta/subject',
                name: 'SubjectInfo',
                component: () => import('@/views/fms/meta/SubjectInfo'),
                meta: { title: '科目资料' }
              },
              {
                path: '/fms/meta/tax_rate',
                name: 'TaxRate',
                component: () => import('@/views/fms/meta/TaxRate'),
                meta: { title: '税率设置' }
              }
            ]
          },
          // {
          //   path: '/fms/invoice',
          //   component: RouteView,
          //   meta: { title: '发票中心' },
          //   children: [
          //     {
          //       path: '/fms/invoice/registration',
          //       name: 'InvoiceRegistration',
          //       component: () => import('@/views/fms/invoice/InvoiceRegistration'),
          //       meta: { title: '发票登记' }
          //     }
          //   ]
          // }
          {
            path: '/fms/finance',
            name: 'fmsFinance',
            component: RouteView,
            meta: { title: '财务中心' },
            children: [
              {
                path: '/fms/finance/fms_charge_check',
                name: 'FmsChargeCheck',
                component: () => import('@/views/fms/finance/FmsChargeCheck'),
                meta: { title: '应收核销' }
              },
              {
                path: '/fms/finance/fms_pay_check',
                name: 'FmsPayCheck',
                component: () => import('@/views/fms/finance/FmsPayCheck'),
                meta: { title: '应付核销' }
              },
              {
                path: '/fms/finance/money_register',
                name: 'MoneyRegister',
                component: () => import('@/views/fms/finance/MoneyRegister'),
                meta: { title: '资金登记' }
              },
              {
                path: '/fms/finance/money_claim',
                name: 'MoneyClaim',
                component: () => import('@/views/fms/finance/MoneyClaim'),
                meta: { title: '资金认领' }
              },
              {
                path: '/fms/finance/invoice_month',
                name: 'InvoiceMonth',
                component: () => import('@/views/fms/finance/InvoiceMonth'),
                meta: { title: '开票月度汇总表' }
              }
              // {
              //   path: '/fms/finance/charge_verify',
              //   name: 'FmsChargeVerify',
              //   component: () => import('@/views/fms/finance/FmsChargeVerify'),
              //   meta: { title: '应收款核销' }
              // },
              // {
              //   path: '/fms/finance/pay_verify',
              //   name: 'FmsPayVerify',
              //   component: () => import('@/views/fms/finance/FmsPayVerify'),
              //   meta: { title: '应付款核销' }
              // }
            ]
          }
        ]
      },
      {
        path: '/mms',
        name: 'mms',
        component: PageView,
        meta: { title: '综合部', icon: 'icon-mms' },
        children: [
          {
            path: '/mms/meta',
            name: 'MmsMeta',
            component: RouteView,
            meta: { title: '租赁管理' },
            redirect: '/mms/meta/rent_info',
            children: [
              {
                path: '/mms/meta/rent_info',
                name: 'RentInfo',
                component: () => import('@/views/mms/meta/RentInfo'),
                meta: { title: '租户管理' }
              },
              {
                path: '/mms/rent_register',
                name: 'RentRegister',
                component: () => import('@/views/mms/RentRegister'),
                meta: { title: '租赁登记' }
              },
              {
                path: '/mms/business_register',
                name: 'BizRegister',
                component: () => import('@/views/mms/BizRegister'),
                meta: { title: '业务登记' }
              }
            ]
          },
          {
            path: '/mms/contractmg',
            component: RouteView,
            meta: { title: '合同管理' },
            redirect: '/mms/contract',
            children: [
              {
                path: '/mms/contractmg/contract',
                name: 'Contract',
                component: () => import('@/views/mms/Contract'),
                meta: { title: '合同登记' }
              },
              {
                path: '/mms/contractmg/contract_approval',
                name: 'ContractApproval',
                component: () => import('@/views/mms/ContractApproval'),
                meta: { title: '合同审批' }
              },
              {
                path: '/mms/contractmg/contract_done',
                name: 'ContractDone',
                component: () => import('@/views/mms/ContractDone'),
                meta: { title: '合同归档' }
              }
            ]
          },
          {
            path: '/mms/finance',
            name: 'finance',
            component: RouteView,
            meta: { title: '财务中心' },
            redirect: '/mms/finance/charge_bill',
            children: [
              {
                path: '/mms/finance/charge_bill',
                name: 'MmsChargeBill',
                component: () => import('@/views/mms/finance/MmsChargeBill'),
                meta: { title: '应收对账单' }
              }
            ]
          }
        ]
      },
      {
        path: '/cms',
        name: 'cms',
        component: PageView,
        meta: { title: '查验部', icon: 'icon-cms' },
        children: [
          {
            path: '/cms/meta',
            component: RouteView,
            meta: { title: '基础信息' },
            redirect: '/cms/meta/consignor',
            children: [
              {
                path: '/cms/meta/consignor',
                name: 'Consignor',
                component: () => import('@/views/cms/meta/Consignor'),
                meta: { title: '委托人' }
              },
              {
                path: '/cms/meta/operator',
                name: 'OperatorCompany',
                component: () => import('@/views/cms/meta/Operator'),
                meta: { title: '经营单位' }
              },
              {
                path: '/cms/meta/customsCompany',
                name: 'CustomsCompany',
                component: () => import('@/views/cms/meta/CustomsCompany'),
                meta: { title: '报关单位' }
              },
              {
                path: '/cms/meta/transCompany',
                name: 'TransCompany',
                component: () => import('@/views/cms/meta/TransCompany'),
                meta: { title: '运输单位' }
              },
              {
                path: '/cms/meta/bizDepartment',
                name: 'BizDepartment',
                component: () => import('@/views/cms/meta/BizDepartment'),
                meta: { title: '业务部门' }
              },
              {
                path: '/cms/meta/business_type',
                name: 'BizType',
                component: () => import('@/views/cms/meta/BizType'),
                meta: { title: '业务类型' }
              },
              {
                path: '/cms/meta/commodity_type',
                name: 'CommodityType',
                component: () => import('@/views/cms/meta/CommodityType'),
                meta: { title: '货物状态' }
              },
              {
                path: '/cms/meta/trade_type',
                name: 'TradeType',
                component: () => import('@/views/cms/meta/TradeType'),
                meta: { title: '贸易方式' }
              },
              {
                path: '/cms/meta/supervise_type',
                name: 'SuperviseType',
                component: () => import('@/views/cms/meta/SuperviseType'),
                meta: { title: '监管方式' }
              },
              {
                path: '/cms/meta/company_type',
                name: 'CompanyType',
                component: () => import('@/views/cms/meta/CompanyType'),
                meta: { title: '企业类型' }
              },
              {
                path: '/cms/meta/inspect_type',
                name: 'InspectType',
                component: () => import('@/views/cms/meta/InspectType'),
                meta: { title: '查验方式' }
              },
              {
                path: '/cms/meta/source',
                name: 'BizSource',
                component: () => import('@/views/cms/meta/BizSource'),
                meta: { title: '业务来源' }
              },
              {
                path: '/cms/meta/delivery_type',
                name: 'DeliveryType',
                component: () => import('@/views/cms/meta/DeliveryType'),
                meta: { title: '提货方式' }
              }
            ]
          },
          {
            path: '/cms/check_in',
            name: 'CheckIn',
            component: () => import('@/views/cms/CheckIn'),
            meta: { title: '业务登记' },
            hideChildrenInMenu: true,
            children: [
              {
                path: '/cms/check_in/:id',
                name: 'CheckInCUR',
                component: () => import('@/views/cms/CheckInCUR'),
                meta: { title: '新增业务登记', subPage: true, hidden: true }
              }
            ]
          },
          {
            path: '/cms/cms_statics',
            name: 'CmsStatics',
            component: () => import('@/views/cms/CmsStatics'),
            meta: { title: '统计报表' }
          },
          {
            path: '/cms/finance',
            name: 'finance',
            component: RouteView,
            meta: { title: '财务中心' },
            redirect: '/cms/finance/charge_bill',
            children: [
              {
                path: '/cms/finance/charge_bill',
                name: 'CmsChargeBill',
                component: () => import('@/views/cms/finance/CmsChargeData'),
                meta: { title: '应收对账单' }
              }
            ]
          }
        ]
      },
      {
        path: '/cums',
        name: 'cums',
        component: PageView,
        meta: { title: '客服部', icon: 'icon-cums' },
        children: [
          {
            path: '/cums/biz',
            component: RouteView,
            meta: { title: '业务登记' },
            redirect: '/cums/biz/CumsImport',
            children: [
              {
                path: '/cums/biz/import',
                name: 'CumsImport',
                component: () => import('@/views/cums/biz/CumsImport'),
                meta: { title: '接单业务' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/cums/biz/import/:id',
                    name: 'ImportCUR',
                    component: () => import('@/views/cums/biz/ImportCUR'),
                    meta: { title: '新增接单业务', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/cums/biz/clear',
                name: 'CumsClear',
                component: () => import('@/views/cums/biz/CumsClear'),
                meta: { title: '清关业务' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/cums/biz/clear/:id',
                    name: 'ClearCUR',
                    component: () => import('@/views/cums/biz/ClearCUR'),
                    meta: { title: '新增清关业务', subPage: true, hidden: true }
                  }
                ]
              },
              {
                path: '/cums/biz/export',
                name: 'CumsExport',
                component: () => import('@/views/cums/biz/CumsExport'),
                meta: { title: '出库业务' },
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/cums/biz/export/:id',
                    name: 'ExportCUR',
                    component: () => import('@/views/cums/biz/ExportCUR'),
                    meta: { title: '新增出库业务', subPage: true, hidden: true }
                  }
                ]
              }
            ]
          },
          {
            path: '/cums/meta',
            component: RouteView,
            meta: { title: '基础信息' },
            children: [
              {
                path: '/cums/meta/biz_type',
                name: 'CumsBizType',
                component: () => import('@/views/cums/meta/CumsBizType'),
                meta: { title: '业务类型' }
              },
              {
                path: '/cums/meta/cums_door_point',
                name: 'CumsDoorPoint',
                component: () => import('@/views/cums/meta/CumsDoorPoint'),
                meta: { title: '门点' }
              },
              {
                path: '/cums/meta/cums_fixfee',
                name: 'CumsFixFee',
                component: () => import('@/views/cums/meta/CumsFixFee'),
                meta: { title: '固定费用' }
              },
              {
                path: '/cums/meta/cums_fleet',
                name: 'CumsFleet',
                component: () => import('@/views/cums/meta/CumsFleet'),
                meta: { title: '车队' }
              },
              {
                path: '/cums/meta/cums_supplier',
                name: 'CumsSupplier',
                component: () => import('@/views/cums/meta/CumsSupplier'),
                meta: { title: '订舱供应商' }
              },
              {
                path: '/cums/meta/cums_warehouse',
                name: 'CumsWarehouse',
                component: () => import('@/views/cums/meta/CumsWarehouse'),
                meta: { title: '仓库' }
              },
              {
                path: '/cums/meta/harbor',
                name: 'CumsHarbor',
                component: () => import('@/views/cums/meta/Harbor'),
                meta: { title: '港口' }
              },
              {
                path: '/cums/meta/cums_shipcom',
                name: 'CumsShipCompany',
                component: () => import('@/views/cums/meta/ShipCompany'),
                meta: { title: '船公司' }
              },
              {
                path: '/cums/meta/cums_customscom',
                name: 'CumsCustomsCompany',
                component: () => import('@/views/cums/meta/CustomsCompany'),
                meta: { title: '报关行' }
              },
              {
                path: '/cums/meta/cums_dest',
                name: 'CumsDestination',
                component: () => import('@/views/cums/meta/CumsDestination'),
                meta: { title: '运输目的地' }
              },
              {
                path: '/cums/meta/cums_tradetype',
                name: 'CumsTradeType',
                component: () => import('@/views/cums/meta/TradeType'),
                meta: { title: '贸易方式' }
              },
              {
                path: '/cums/meta/cums_unit',
                name: 'CumsUnit',
                component: () => import('@/views/cums/meta/CumsUnit'),
                meta: { title: '单位' }
              },
              {
                path: '/cums/meta/cums_customer',
                name: 'CumsCustomer',
                component: () => import('@/views/cums/meta/Customer'),
                meta: { title: '客服人员' }
              },
              {
                path: '/cums/meta/cums_target',
                name: 'CumsTarget',
                component: () => import('@/views/cums/meta/CumsTarget'),
                meta: { title: '付款对象' }
              }
            ]
          },
          {
            path: '/cums/finance',
            name: 'CumsFinance',
            component: RouteView,
            meta: { title: '财务中心' },
            children: [
              {
                path: '/cums/finance/cums_charge_list',
                name: 'CumsChargeData',
                component: () => import('@/views/cums/finance/CumsChargeData'),
                meta: { title: '应收对账单' }
              },
              {
                path: '/cums/finance/cums_pay_list',
                name: 'CumsPayData',
                component: () => import('@/views/cums/finance/CumsPayData'),
                meta: { title: '应付对账单' }
              },
              {
                path: '/cums/finance/cums_profit',
                name: 'CumsProfitDetail',
                component: () => import('@/views/cums/finance/CumsProfitDetail'),
                meta: { title: '利润明细表' }
              }
            ]
          },
          {
            path: '/cums/cums_statics',
            name: 'CumsStatics',
            component: () => import('@/views/cums/CumsStatics'),
            meta: { title: '统计报表' }
          }
        ]
      },
      {
        path: '/common',
        name: 'common',
        component: PageView,
        meta: { title: '公共信息', icon: 'icon-common' },
        children: [
          {
            path: '/common/customer',
            name: 'Customer',
            component: () => import('@/views/common/Customer'),
            meta: { title: '客户信息' },
            hideChildrenInMenu: true,
            children: [
              {
                path: '/common/customer/:id',
                name: 'CustomerCUR',
                component: () => import('@/views/common/CustomerCUR'),
                meta: { title: '新增客户信息', subPage: true, hidden: true }
              }
            ]
          },
          {
            path: '/common/supplier_info',
            name: 'SupplierInfo',
            component: () => import('@/views/common/SupplierInfo'),
            meta: { title: '供应商信息' }
          },
          {
            path: '/common/sys_customer',
            name: 'SysCustomer',
            component: () => import('@/views/common/SysCustomer'),
            meta: { title: 'SAP客户信息' }
          },
          {
            path: '/common/customer_info',
            name: 'CustomerInfo',
            component: () => import('@/views/common/CustomerInfo'),
            meta: { title: '客户供应商信息' }
          },
          {
            path: '/common/sys_supplier',
            name: 'SysSupplier',
            component: () => import('@/views/common/SysSupplier'),
            meta: { title: 'SAP供应商信息' }
          },
          {
            path: '/common/fee',
            name: 'Fee',
            component: () => import('@/views/common/Fee'),
            meta: { title: '费用资料' }
          },
          {
            path: '/common/box_info',
            name: 'BoxInfo',
            component: () => import('@/views/common/BoxInfo'),
            meta: { title: '箱型信息' }
          },
          {
            path: '/common/currency_type',
            name: 'CurrencyType',
            component: () => import('@/views/common/CurrencyType'),
            meta: { title: '币种类型' }
          },
          {
            path: '/common/registration',
            name: 'InvoiceRegistration',
            component: () => import('@/views/common/InvoiceRegistration'),
            meta: { title: '发票登记' }
          }
        ]
      },
      {
        path: '/sysmanage',
        name: 'sysmanage',
        component: PageView,
        redirect: '/sysmanage/user',
        meta: { title: '系统管理', icon: 'icon-sys', permission: [ 'user' ] },
        children: [
          // {
          //   path: '/sysmanage/company',
          //   name: 'Company',
          //   component: () => import(/* webpackChunkName: "result" */ '@/views/sysmanage/Company'),
          //   meta: { title: '组织机构', keepAlive: false, hiddenHeaderContent: false, permission: [ 'user' ] }
          // },
          {
            path: '/sysmanage/user',
            name: 'User',
            component: () => import(/* webpackChunkName: "result" */ '@/views/sysmanage/User'),
            meta: { title: '用户管理', keepAlive: false, hiddenHeaderContent: false, permission: [ 'user' ] }
          },
          {
            path: '/sysmanage/role',
            name: 'Role',
            component: () => import('@/views/sysmanage/Role'),
            meta: { title: '角色管理', keepAlive: false, hiddenHeaderContent: false, permission: [ 'user' ] }
          }
        ]
      }
      // forms
      // {
      //   path: '/form',
      //   redirect: '/form/base-form',
      //   component: PageView,
      //   meta: { title: '表单页', icon: 'form', permission: [ 'form' ] },
      //   children: [
      //     {
      //       path: '/form/base-form',
      //       name: 'BaseForm',
      //       component: () => import('@/views/form/BasicForm'),
      //       meta: { title: '基础表单', keepAlive: true, permission: [ 'form' ] }
      //     },
      //     {
      //       path: '/form/step-form',
      //       name: 'StepForm',
      //       component: () => import('@/views/form/stepForm/StepForm'),
      //       meta: { title: '分步表单', keepAlive: true, permission: [ 'form' ] }
      //     },
      //     {
      //       path: '/form/advanced-form',
      //       name: 'AdvanceForm',
      //       component: () => import('@/views/form/advancedForm/AdvancedForm'),
      //       meta: { title: '高级表单', keepAlive: true, permission: [ 'form' ] }
      //     }
      //   ]
      // },

      // profile
      // {
      //   path: '/profile',
      //   name: 'profile',
      //   component: RouteView,
      //   redirect: '/profile/basic',
      //   meta: { title: '详情页', icon: 'profile', permission: [ 'profile' ] },
      //   children: [
      //     {
      //       path: '/profile/basic',
      //       name: 'ProfileBasic',
      //       component: () => import('@/views/profile/basic/Index'),
      //       meta: { title: '基础详情页', permission: [ 'profile' ] }
      //     },
      //     {
      //       path: '/profile/advanced',
      //       name: 'ProfileAdvanced',
      //       component: () => import('@/views/profile/advanced/Advanced'),
      //       meta: { title: '高级详情页', permission: [ 'profile' ] }
      //     }
      //   ]
      // },

      // result
      // {
      //   path: '/result',
      //   name: 'result',
      //   component: PageView,
      //   redirect: '/result/success',
      //   meta: { title: '结果页', icon: 'check-circle-o', permission: [ 'result' ] },
      //   children: [
      //     {
      //       path: '/result/success',
      //       name: 'ResultSuccess',
      //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
      //       meta: { title: '成功', keepAlive: false, hiddenHeaderContent: true, permission: [ 'result' ] }
      //     },
      //     {
      //       path: '/result/fail',
      //       name: 'ResultFail',
      //       component: () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),
      //       meta: { title: '失败', keepAlive: false, hiddenHeaderContent: true, permission: [ 'result' ] }
      //     }
      //   ]
      // },

      // Exception
      // {
      //   path: '/exception',
      //   name: 'exception',
      //   component: RouteView,
      //   redirect: '/exception/403',
      //   meta: { title: '异常页', icon: 'warning', permission: [ 'exception' ] },
      //   children: [
      //     {
      //       path: '/exception/403',
      //       name: 'Exception403',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
      //       meta: { title: '403', permission: [ 'exception' ] }
      //     },
      //     {
      //       path: '/exception/404',
      //       name: 'Exception404',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
      //       meta: { title: '404', permission: [ 'exception' ] }
      //     },
      //     {
      //       path: '/exception/500',
      //       name: 'Exception500',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
      //       meta: { title: '500', permission: [ 'exception' ] }
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/test',
    component: BlankLayout,
    redirect: '/test/home',
    children: [
      {
        path: 'home',
        name: 'TestHome',
        component: () => import('@/views/Home')
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }

]
