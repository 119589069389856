// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { FormModel } from 'ant-design-vue'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'
import global_ from './Global'

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './components/global.less'
import './assets/style.less'
import Print from 'vue-print-nb'
import hasPerms from '@/utils/hasPerms'
import * as echarts from 'echarts'

Vue.config.productionTip = false
Vue.prototype.GLOBAL = global_
Vue.prototype.$echarts = echarts
// mount axios Vue.$http and this.$http
Vue.use(VueAxios)
Vue.use(FormModel)
Vue.use(Print);
Vue.use(hasPerms)
new Vue({
  router,
  store,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
