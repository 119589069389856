import T from 'ant-design-vue/es/table/Table'
// import get from 'lodash.get'
import Vue from 'vue'
export default {
  data () {
    return {
      // needTotalList: [],
      selectedRows: [],
      selectedRowKeys: [],
      localLoading: false,
      localDataSource: [],
      localPagination: Object.assign({}, this.pagination),
      customColumnsVisible: [], // 自定义列信息是否显示
      visibleColumns: [], // 可显示的列集合
      columnsOrder: {}, // 列字段对应的排序序号 eg: { id: 1 }
      filterDropdownVisible: false
    }
  },
  props: Object.assign({}, T.props, {
    rowKey: {
      type: [String, Function],
      default: 'key'
    },
    filterColumn: { // 是否添加过滤列功能
      type: Boolean,
      default: true
    },
    dynamicColumns: { // 是否是动态列
      type: Boolean,
      default: false
    },
    data: {
      type: Function,
      required: true
    },
    pageNum: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'middle'
    },
    /**
     * alert: {
     *   show: true,
     *   clear: Function
     * }
     */
    alert: {
      type: [Object, Boolean],
      default: null
    },
    rowSelection: {
      type: Object,
      default: null
    },
    showPagination: {
      type: String | Boolean,
      default: 'auto'
    },
    /**
     * enable page URI mode
     *
     * e.g:
     * /users/1
     * /users/2
     * /users/3?queryParam=test
     * ...
     */
    pageURI: {
      type: Boolean,
      default: false
    }
  }),
  watch: {
    'localPagination.current' (val) {
      this.pageURI && this.$router.push({
        ...this.$route,
        name: this.$route.name,
        params: Object.assign({}, this.$route.params, {
          pageNo: val
        })
      })
    },
    columns (val) {
      if (this.dynamicColumns) {
        // console.log(889)
        this.visibleColumns = val
      }
    },
    pageNum (val) {
      Object.assign(this.localPagination, {
        current: val
      })
    },
    pageSize (val) {
      Object.assign(this.localPagination, {
        pageSize: val
      })
    },
    showSizeChanger (val) {
      Object.assign(this.localPagination, {
        showSizeChanger: val
      })
    }
  },
  created () {
    if (this.filterColumn) {
      const columnsIndex = []
      this.columns.forEach(c => {
        if ((c.dataIndex && c.dataIndex !== 'action') || c.children) {
          columnsIndex.push(c.dataIndex || c.key)
          var sortColums = Vue.ls.get(this.$store.getters.userInfo.id + this.$route.name + 'sort')
          if (sortColums) {
            console.info(sortColums)
            const myMap = new Map(Object.entries(sortColums));
            for (const [key, value] of myMap.entries()) {
              if ((c.dataIndex || c.key) === key) {
                this.$set(this.columnsOrder, c.dataIndex || c.key, value)
              }
            }
          } else {
            this.$set(this.columnsOrder, c.dataIndex || c.key, 99)
          }
          
        }
      })
      this.customColumnsVisible = Vue.ls.get(this.$store.getters.userInfo.id + this.$route.name) || columnsIndex
      this.getVisibleColumns()
    }
    const { pageNo } = this.$route.params
    const localPageNum = this.pageURI && (pageNo && parseInt(pageNo)) || this.pageNum
    this.localPagination = ['auto', true].includes(this.showPagination) && Object.assign({}, this.localPagination, {
      current: localPageNum,
      pageSize: this.pageSize,
      showSizeChanger: this.showSizeChanger,
      showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
    }) || false
    // console.log('this.localPagination', this.localPagination)
    // this.needTotalList = this.initTotalList(this.columns)
    this.loadData()
  },
  methods: {
    /**
     * 表格重新加载方法
     * 如果参数为 true, 则强制刷新到第一页
     * @param Boolean bool
     */
    refresh (bool = false) {
      bool && (this.localPagination = Object.assign({}, {
        current: 1, pageSize: this.localPagination.pageSize
      }))
      // this.clearSelected()
      this.loadData()
    },
    /**
     * 加载数据方法
     * @param {Object} pagination 分页选项器
     * @param {Object} filters 过滤条件
     * @param {Object} sorter 排序条件
     */
    loadData (pagination, filters, sorter) {
      this.localLoading = true
      const _pageNum = (pagination && pagination.current) || this.showPagination && this.localPagination.current || this.pageNum
      const _pageSize = (pagination && pagination.pageSize) || this.showPagination && this.localPagination.pageSize || this.pageSize
      // console.log(sorter)
      const parameter = Object.assign({
        page: _pageNum, // (_pageNum - 1) * _pageSize,
        page_size: _pageSize
      },
      (sorter && sorter.field && { order: sorter.field }) || {},
      (sorter && sorter.order && { order_type: sorter.order === 'ascend' ? 'asc' : 'desc' }) || {},
      { ...filters })
      // console.log('parameter', parameter)
      const result = this.data(parameter)
      // 对接自己的通用数据接口需要修改下方代码中的 r.pageNo, r.totalCount, r.data
      // eslint-disable-next-line
      if ((typeof result === 'object' || typeof result === 'function') && typeof result.then === 'function') {
        result.then(r => {
          this.localPagination = this.showPagination && Object.assign({}, this.localPagination, {
            current: _pageNum, // 返回结果中的当前分页数
            total: r.total, // 返回结果中的总记录数
            showSizeChanger: this.showSizeChanger,
            pageSize: (pagination && pagination.pageSize) ||
              this.localPagination.pageSize,
            pageSizeOptions: ['1', '5', '10', '30', '50', '100', '200']
          }) || false
          // 为防止删除数据后导致页面当前页面数据长度为 0 ,自动翻页到上一页
          if (r.list.length === 0 && this.showPagination && this.localPagination.current > 1) {
            this.localPagination.current--
            this.loadData()
            return
          }

          // 这里用于判断接口是否有返回 r.totalRows 且 this.showPagination = true 且 pageNo 和 pageSize 存在 且 totalCount 小于等于 pageNo * pageSize 的大小
          // 当情况满足时，表示数据不满足分页大小，关闭 table 分页功能
          try {
            if ((['auto', true].includes(this.showPagination) && r.totalRows <= (r.totalPages * this.localPagination.pageSize))) {
              this.localPagination.hideOnSinglePage = true
            }
          } catch (e) {
            this.localPagination = false
          }
          // console.log('loadData -> this.localPagination', this.localPagination)
          this.localDataSource = r.list // 返回结果中的数组数据
          this.localLoading = false
        })
      }
    },
    // initTotalList (columns) {
    //   const totalList = []
    //   columns && columns instanceof Array && columns.forEach(column => {
    //     if (column.needTotal) {
    //       totalList.push({
    //         ...column,
    //         total: column.total || 0
    //       })
    //     }
    //   })
    //   return totalList
    // },
    /**
     * 用于更新已选中的列表数据 total 统计
     * @param selectedRowKeys
     * @param selectedRows
     */
    updateSelect (selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows
      this.selectedRowKeys = selectedRowKeys
      // const list = this.needTotalList
      // this.needTotalList = list.map(item => {
      //   return {
      //     ...item,
      //     total: selectedRows.reduce((sum, val) => {
      //       const total = sum + parseInt(get(val, item.dataIndex))
      //       return isNaN(total) ? 0 : total
      //     }, 0)
      //   }
      // })
    },
    /**
     * 清空 table 已选中项
     */
    clearSelected () {
      if (this.rowSelection) {
        this.rowSelection.onChange([], [])
        this.updateSelect([], [])
      }
    },
    /**
     * 处理交给 table 使用者去处理 clear 事件时，内部选中统计同时调用
     * @param callback
     * @returns {*}
     */
    renderClear (callback) {
      if (this.selectedRowKeys.length <= 0) return null
      return (
        <a style="margin-left: 24px" onClick={() => {
          callback()
          this.clearSelected()
        }}>清空</a>
      )
    },
    renderAlert () {
      // 绘制统计列数据
      // const needTotalItems = this.needTotalList.map((item) => {
      //   return (<span style="margin-right: 12px">
      //     {item.title}总计 <a style="font-weight: 600">{!item.customRender ? item.total : item.customRender(item.total)}</a>
      //   </span>)
      // })

      // 绘制 清空 按钮
      const clearItem = (typeof this.alert.clear === 'boolean' && this.alert.clear) ? (
        this.renderClear(this.clearSelected)
      ) : (this.alert !== null && typeof this.alert.clear === 'function') ? (
        this.renderClear(this.alert.clear)
      ) : null

      // 绘制 alert 组件
      return (
        <a-alert showIcon={true} style="margin-bottom: 16px">
          <template slot="message">
            {/* {this.rowSelection ? <span style="margin-right: 12px">已选择: <a style="font-weight: 600">{this.selectedRows.length}</a></span> : ''} */}
            {this.alert.msg}
            {clearItem}
          </template>
        </a-alert>
      )
    },
    getVisibleColumns() {
      const visibleCols = []
      this.columns.forEach(c => {
        if ((!c.dataIndex && !c.children) || c.dataIndex === 'action' || this.customColumnsVisible.indexOf(c.dataIndex || c.key) > -1) {
          visibleCols.push(c)
        }
      })
      this.visibleColumns = visibleCols.sort((a, b) => {
        return parseInt(this.columnsOrder[a.dataIndex || a.key]) - parseInt(this.columnsOrder[b.dataIndex || b.key])
      })
      this.customColumnsVisible = this.visibleColumns.map(c => c.dataIndex)
      // console.info(this.columnsOrder)
      // console.info(this.visibleColumns)
    },
    handleColumnChecked() {
      // 获取可显示的列信息
      this.getVisibleColumns()
      // const myMap = new Map(Object.entries(this.columnsOrder));
      // console.log(myMap);
      Vue.ls.set(this.$store.getters.userInfo.id + this.$route.name + 'sort', this.columnsOrder)
      Vue.ls.set(this.$store.getters.userInfo.id + this.$route.name, this.customColumnsVisible)
      this.filterDropdownVisible = false
    }
  },

  render () {
    const props = {}
    const localKeys = Object.keys(this.$data)
    const showAlert = (typeof this.alert === 'object' && this.alert !== null && this.alert.show)
    // (typeof this.alert === 'object' && this.alert !== null && this.alert.show) && typeof this.rowSelection.selectedRowKeys !== 'undefined' || this.alert

    Object.keys(T.props).forEach(k => {
      const localKey = `local${k.substring(0, 1).toUpperCase()}${k.substring(1)}`
      if (localKeys.includes(localKey)) {
        props[k] = this[localKey]
        return props[k]
      }
      if (k === 'rowSelection') {
        if (showAlert && this.rowSelection) {
          // 如果需要使用alert，则重新绑定 rowSelection 事件
          // console.log('this.rowSelection', this.rowSelection)
          props[k] = {
            ...this.rowSelection,
            selectedRows: this.selectedRows,
            selectedRowKeys: this.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.updateSelect(selectedRowKeys, selectedRows)
              typeof this[k].onChange !== 'undefined' && this[k].onChange(selectedRowKeys, selectedRows)
            }
          }
          return props[k]
        } else if (!this.rowSelection) {
          // 如果没打算开启 rowSelection 则清空默认的选择项
          props[k] = null
          return props[k]
        }
      }
      this[k] && (props[k] = this[k])
      return props[k]
    })

    // 自定义显示列 start
    if (this.filterColumn) {
      props['getPopupContainer'] = (triggerNode) => document.getElementById('table-wrapper')
      const _cloumns = [ ...this.visibleColumns ] // props.columns
      _cloumns[_cloumns.length - 1] = {
        ..._cloumns[_cloumns.length - 1],
        filterDropdown: ({ confirm, clearFilters }) => (
          <div style='padding: 8px 0 8px 20px;width: 420px'>
            <a-checkbox-group v-model={this.customColumnsVisible}>
              {
                this.columns.map(v => {
                  if ((v.dataIndex && v.dataIndex !== 'action') || v.children) {
                    return (<a-checkbox value={v.dataIndex || v.key} style='width: 200px; margin: 3px 0'><a-input v-model={this.columnsOrder[v.dataIndex || v.key]} style="width: 60px" placeholder="序号"/> {v.title}</a-checkbox>)
                  }
                })
              }
            </a-checkbox-group>
            <p style="margin:10px 20px;text-align: right"><a-button type="primary" size="small" onClick={this.handleColumnChecked}>确定</a-button></p>
          </div>
        ),
        filterIcon: filtered => <a-icon type='setting' theme='filled' style='fontSize: 16px;margin-top: 18px;padding: 0 5px'/>,
        filterDropdownVisible: this.filterDropdownVisible,
        onFilterDropdownVisibleChange: (visible) => {
          this.filterDropdownVisible = visible
        }
      }
      props['columns'] = _cloumns
    }
    // 自定义显示列 end
    const table = (
      <a-table {...{ props, scopedSlots: { ...this.$scopedSlots } }}
                onChange={this.loadData}
                onExpand={ (expanded, record) => { this.$emit('expand', expanded, record) } }
                customRow={(record, index) => {
                  return {
                    on: {
                      dblclick: (event) => {
                        this.$emit('dblclick', record, index)
                      }
                    }
                  };
                }}>
        { Object.keys(this.$slots).map(name => (<template slot={name}>{this.$slots[name]}</template>)) }
      </a-table>
    )
    return (
      <div class="table-wrapper" id="table-wrapper">
        { showAlert ? this.renderAlert() : null }
        { table }
      </div>
    )
  }
}
