<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <template v-for="(query, index) in queryFields">
            <a-col v-bind="GLOBAL.queryColSpan" :key="index">
              <a-form-item :label="query.label">
                <a-select
                  v-model="queryParam[query.name]"
                  v-if="query.fieldType === 'select'"
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  class="full-width"
                >
                  <a-select-option v-for="d in query.options" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
                <a-range-picker
                  v-else-if="query.fieldType === 'range'"
                  @change="onDateChange(query.name, ...arguments)"
                />
                <a-input v-model="queryParam[query.queryType + query.name]" v-else />
              </a-form-item>
            </a-col>
          </template>
          <!-- <a-col :xs="6">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col> -->
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
      <a-button icon="upload" @click="exportSheet" v-if="exportFun">导出</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 800 }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>
    <a-modal
      :visible="formVisible"
      :confirmLoading="confirmLoading"
      :width="520 + (24 / modelLayout.span - 2) * 200"
      :maskClosable="false"
      :title="modalFlag === 'create' ? '创建' + modelTitle : '编辑' + modelTitle"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="originForm" :rules="rules" ref="form">
        <a-row :gutter="modelLayout.gutter" type="flex" justify="start">
          <template v-for="(field, index) in formFields">
            <a-divider orientation="left" :key="'d' + index" v-if="field.divider && field.dividerName">{{
              field.dividerName
            }}</a-divider>
            <a-divider v-else-if="field.divider" :key="'d' + index" />
            <a-col :span="field.span || modelLayout.span" :key="index" v-if="field.label">
              <a-form-model-item :label="field.label" :prop="rules[field.name] && field.name">
                <a-radio-group
                  :options="field.options"
                  v-model="originForm[field.name]"
                  v-if="field.type === 'radio'"
                ></a-radio-group>
                <a-select
                  v-model="originForm[field.name]"
                  v-else-if="field.type === 'select'"
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  @change="field.eventFun"
                  :mode="field.mode || 'default'"
                  :ref="field.name"
                  class="full-width"
                >
                  <a-select-option v-for="d in field.options" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
                <a-switch v-model="originForm[field.name]" v-bind="field.options" v-else-if="field.type === 'switch'" />
                <a-textarea v-model="originForm[field.name]" v-else-if="field.type === 'textarea'" />
                <a-input-number
                  v-model="originForm[field.name]"
                  class="full-width"
                  v-else-if="field.type === 'number'"
                />
                <a-date-picker
                  v-model="originForm[field.name]"
                  :key="field.name"
                  class="full-width"
                  v-else-if="field.type === 'date'"
                />
                <edit-table
                  v-else-if="field.type === 'editable'"
                  :columns="field.extra.columns"
                  :sourceData="originForm[field.name]"
                  @getTableDate="getData($event, field.name)"
                ></edit-table>
                <a-input
                  v-model="originForm[field.name]"
                  :placeholder="field.placeholder"
                  :disabled="field.disabled || false"
                  :ref="field.ref"
                  v-else
                />
              </a-form-model-item>
            </a-col>
          </template>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import EditTable from '@/components/Table/EditTable'
import moment from 'moment'
import { getFirstLetter } from '@/utils/util'
import { downloadExcel } from '@/api/common'

export default {
  components: {
    STable,
    EditTable
  },
  props: {
    queryFields: {
      type: Array,
      default: function() {
        return []
      }
    },
    defaultQueryParam: {
      // 默認查询参数
      type: Object,
      default: function() {
        return {}
      }
    },
    columns: {
      type: Array,
      default: function() {
        return []
      }
    },
    // loadData: {
    //   type: Function,
    //   required: true
    // },
    rules: {
      type: Object,
      default: function() {
        return {}
      }
    },
    modelTitle: {
      type: String,
      default: ''
    },
    modelLayout: {
      type: Object,
      default: function() {
        return {
          gutter: 20,
          span: 12
        }
      }
    },
    formFields: {
      // 表单字段
      type: Array,
      default: function() {
        // const eg = [{
        //   label: '部门',
        //   name: 'departments',
        //   type: 'select', // number select default radio
        //   options: [],
        //   func: Function,
        //   eventFun: Function,
        //   params: ''
        // }]
        return []
      }
    },
    moduleName: {
      type: String,
      default: ''
    },
    getPageFun: {
      type: Function,
      required: true
    },
    deleteFun: {
      type: Function,
      required: true
    },
    createFun: {
      type: Function,
      required: true
    },
    updateFun: {
      type: Function,
      required: true
    },
    exportFun: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      queryParam: {},
      selectedRowKeys: [],
      loadData: parameter => {
        const params = { ...this.queryParam, ...this.defaultQueryParam }
        return this.getPageFun(this.moduleName, Object.assign(parameter, params)).then(res => {
          return res
        })
      },
      fieldOpsMap: {},
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      originForm: {},
      currentRecord: null,
      formBasicInfoInit: false // 表单基础数据初始化(下拉框内容等等),避免重复获取
    }
  },
  created() {
    this.handleFormReset()
  },
  methods: {
    moment,
    handleFormReset() {
      this.formFields.forEach(f => {
        switch (f.type) {
          case 'select':
            if (f.mode === 'multiple') {
              this.$set(this.originForm, f.name, f.defaultValue || [])
            } else {
              this.$set(this.originForm, f.name, f.defaultValue || null)
            }
            break
          case 'date':
            const _default = f.defaultValue ? moment(f.defaultValue, 'YYYY/MM/DD') : null
            this.$set(this.originForm, f.name, _default)
            break
          case 'switch':
            this.$set(this.originForm, f.name, f.options.checked || false)
            break
          case 'number':
            this.$set(this.originForm, f.name, f.defaultValue || null)
            break
          case 'hidden':
            this.$set(this.originForm, f.name, f.defaultValue)
            break
          case 'editable':
            this.$set(this.originForm, f.name, [])
            break
          default:
            this.$set(this.originForm, f.name, f.defaultValue || '')
        }
      })
    },
    getData(data, field) {
      this.originForm[field] = data
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    onDateChange(field, date, dateString) {
      if (date[0]) {
        const _date = [date[0].startOf('day').valueOf(), date[1].endOf('day').valueOf()]
        this.queryParam['@' + field] = _date
      } else {
        this.queryParam['@' + field] = []
      }
    },
    handleCreate() {
      this.modalFlag = 'create'
      this.handleFormReset()
      if (!this.formBasicInfoInit) {
        this.getFormBasicInfo()
      }
      this.formVisible = true
      this.$nextTick(_ => {
        this.$emit('modelShow', this)
        // this.$refs.form.resetFields();
        // this.$refs.form.clearValidate();
      })
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.handleFormReset()
      if (!this.formBasicInfoInit) {
        this.getFormBasicInfo()
      }
      this.formVisible = true
      this.formFields.forEach(f => {
        if (f.type === 'date') {
          record[f.name] = record[f.name] ? moment(record[f.name], 'YYYY/MM/DD') : null
          // this.originForm[f.name] = record[f.name] ? moment(record[f.name], 'YYYY/MM/DD') : null
        } else {
          // this.originForm[f.name] = record[f.name] || this.originForm[f.name]
          record[f.name] = record[f.name] || this.originForm[f.name]
        }
      })
      // this.originForm = record
      Object.assign(this.originForm, record)
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
        this.$emit('modelShow', this)
      })
    },
    getFormBasicInfo() {
      this.formBasicInfoInit = true
      this.formFields.forEach(field => {
        if (field.type === 'select' && field.func) {
          field.func(field.params || null).then(v => {
            field.options = v
            this.$set(this.fieldOpsMap, field.name, v)
          })
        }
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          let data = { ids: _ids }
          if (this.moduleName === 'user') {
            data = _ids
          }
          this.deleteFun(this.moduleName, data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = []
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = this.createFun
          if (this.modalFlag === 'update') {
            func = this.updateFun
          }
          this.formFields.forEach(o => {
            if (o.type === 'select_name') {
              this.originForm[o.name] = this.originForm[o.ref] ? this.$refs[o.ref][0].$el.innerText : ''
            }
          })
          const data = { ...this.originForm }
          this.formFields.forEach(f => {
            if (f.type === 'date') {
              data[f.name] = data[f.name] ? moment(data[f.name]).valueOf() : null
            }
          })
          func(this.moduleName, data)
            .then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              this.$refs.table.refresh(this.modalFlag === 'create')
            })
            .catch(_ => {
              // this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false
        }
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    exportSheet() {
      this.exportFun(this.moduleName, { ...this.queryParam }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = ``;
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
            )
            const link = document.createElement(`a`);
            link.style.display = 'none';
            link.href = url;
            link.setAttribute(`download`, fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
