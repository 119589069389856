import { axios } from '@/utils/request'
const api = {
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info'
}
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return axios({
    url: `sys/user/login`,
    method: 'post',
    noTips: true,
    // baseURL: '/mock',
    data: parameter
  })
}

export function getSmsCaptcha (parameter) {
  return axios({
    url: api.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return axios({
    url: '/user/info',
    method: 'get',
    baseURL: '/mock',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getUserInfo () {
  return axios({
    url: 'sys/user/getUserInfo',
    method: 'get'
  })
}

export function getCurrentUserNav (token) {
  return axios({
    url: `sys/menu/roleTree`,
    method: 'get'
  })
}

export function logout () {
  return axios({
    url: '/auth/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
