<template>
  <div>
    <a-drawer
      :title="title"
      :placement="placement"
      :closable="false"
      :visible="visible"
      :get-container="() => container()"
      :mask="false"
      class="main"
    >
      <div>
        <slot name="summary"></slot>
      </div>
      <div>
        <slot name="list"></slot>
      </div>
    </a-drawer>
  </div>
</template>

<script>

export default ({
  props: {
    title: {
      type: String,
      default: '选择内容'
    },
    placement: {
      type: String,
      default: 'left'
    },
    visible: {
      type: Boolean,
      default: false
    },
    container: {
      type: Function,
      default: () => {}
    }
  }
})
</script>

<style>
.summary-context {
  font-size: 18px;
  font-weight: bolder;
}
.main p {
  margin-bottom: 0 !important;
}
.main div {
  margin-bottom: 0 !important;
}
</style>
