const hasPerms = {
  install(Vue, options) {
    Vue.directive('perms', {
      inserted(el, binding) {
        // console.log('v-perms >>>>>>>', el, binding.value)
        if (binding.value) {
          if (!checkBtnKey(binding.value)) {
            el.remove()
          }
        }
      }
    })
  }
}

function checkBtnKey(key) {
  const permsData = []
  JSON.parse(sessionStorage.getItem('buttonList') || []).map(auth => {
    permsData.push(auth.permissions)
  })
  if (permsData.includes(key)) {
    return true
  } else {
    return false
  }
}

export default hasPerms
