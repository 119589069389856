import { axios } from '@/utils/request'

export function getCustomerList() {
  return axios({
    url: `common/customer/option`,
    method: 'get'
  })
}

export function getCustomerContactList(params) {
  return axios({
    url: `common/customer/contact_list`,
    method: 'get',
    params
  })
}

export function getCommonOptions(type, params) {
  return axios({
    url: `common/${type}/option`,
    method: 'get',
    params
  })
}

export function queryCustomerInfoList(type, data) {
  return axios({
    url: `common/${type}/queryCustomerInfoList`,
    method: 'post',
    data
  })
}

export function getCommonPage(type, params) {
  return axios({
    url: `common/${type}/list`,
    method: 'get',
    params
  })
}

export function getCommonInvoicePage(type, data) {
  return axios({
    url: `common/${type}/list`,
    method: 'get',
    params: data
  })
}

export function getCommonNoPage(type, data) {
  return axios({
    url: `common/${type}/invoice_list`,
    method: 'post',
    data
  })
}

export function getLeaseInfoListByDepartment(data) {
  return axios({
    url: `mms/lease_info/getLeaseInfoListByDepartment`,
    method: 'post',
    data
  })
}

export function createCommonInfo(type, data) {
    return axios({
      url: `common/${type}/create`,
      method: 'post',
      data
    })
}

export function saveCommonInfo(type, data) {
  return axios({
    url: `common/${type}/save`,
    method: 'post',
    data
  })
}

export function updateCommonInfo(type, data) {
    return axios({
        url: `common/${type}/update`,
        method: 'post',
        data
    })
}

export function updateInvoiceCommonInfo(type, data) {
  return axios({
    url: `common/${type}/updateInvoice`,
    method: 'post',
    data
  })
}

export function deleteCommonInfo(type, data) {
    return axios({
        url: `common/${type}/delete`,
        method: 'post',
        data
    })
}

export function upload(data) {
  return axios({
    url: `file/upload`,
    method: 'post',
    data
  })
}

export function downLoad(uuid) {
  return axios({
    url: `file/download?uuid=` + uuid,
    responseType: 'blob',
    method: 'get'
  })
}

export function customerImport(data) {
  return axios({
    url: `common/sys/customer/customer_import`,
    method: 'post',
    data
  })
}

export function importTemp(data) {
  return axios({
    url: `common/customer_info/import_temp`,
    method: 'post',
    data
  })
}

export function supplierImport(data) {
  return axios({
    url: `common/sys/supplier/supplier_import`,
    method: 'post',
    data
  })
}

export function subjectOptions() {
  return axios({
    url: `fms/subject/subjectOptions`,
    method: 'get'
  })
}

export function getCustomer(params) {
  return axios({
    url: `common/customer_info/getCustomer`,
    method: 'get',
    params
  })
}

export function getCustomerCopy(params) {
  return axios({
    url: `common/customer_info/getCustomerCopy`,
    method: 'get',
    params
  })
}

export function getCustomerInfo(params) {
  return axios({
    url: `common/customer_info/getCustomerInfo`,
    method: 'get',
    params
  })
}

export function getSupplier(params) {
  return axios({
    url: `common/customer_info/getSupplier`,
    method: 'get',
    params
  })
}

export function getSupplierAndByName(params) {
  return axios({
    url: `common/customer_info/getSupplierAndByName`,
    method: 'get',
    params
  })
}

export function saveCustomer(params) {
  return axios({
    url: `common/customer_info/saveCustomer`,
    method: 'post',
    data: params
  })
}

export function getInvalidCustomer(data) {
  return axios({
    url: `common/customer_info/getInvalidCustomer`,
    method: 'post',
    data
  })
}

export function updateSAPCode(data) {
  return axios({
    url: `common/customer_info/updateSAPCode`,
    method: 'post',
    params: data
  })
}

export function downloadExcel(params) {
  return axios({
    url: `file/download_excel`,
    method: 'get',
    responseType: 'blob',
    params
  })
}

export function exportCustomerInfoList(data) {
  return axios({
    url: `/common/customer_info/exportCustomerInfoList`,
    method: `post`,
    data
  })
}

export function initOfficeBusinessExpenses() {
  return axios({
    url: `/index/init`,
    method: 'get'
  })
}

export function pageCurrencyType(data) {
  return axios({
    url: `/common/currency_type/listCurrencyType`,
    method: 'post',
    data
  })
}

export function saveOrUpdateCurrencyType(data) {
  return axios({
    url: `/common/currency_type/saveOrUpdateCurrencyType`,
    method: 'post',
    data
  })
}

export function deleteCurrencyType(data) {
  return axios({
    url: `/common/currency_type/deleteCurrencyType`,
    method: 'post',
    data
  })
}

export function pageCurrencyTypeValidity(data) {
  return axios({
    url: `/common/currency_type/listCurrencyTypeValidity`,
    method: 'post',
    data
  })
}

export function saveOrUpdateCurrencyTypeValidity(data) {
  return axios({
    url: `/common/currency_type/saveOrUpdateCurrencyTypeValidity`,
    method: 'post',
    data
  })
}

export function deleteCurrencyTypeValidity(data) {
  return axios({
    url: `/common/currency_type/deleteCurrencyTypeValidity`,
    method: 'post',
    data
  })
}

export function handleQueryAreas(data) {
  return axios({
    url: `/common/area_info/queryAreas`,
    method: 'post',
    data
  })
}

export function queryCustomerInfoBlackLlist(data) {
  return axios({
    url: `common/customer_blacklist/pageList`,
    method: 'post',
    data
  })
}

export function saveBlackList(data) {
  return axios({
    url: `common/customer_blacklist/saveBlackList`,
    method: 'post',
    data
  })
}

export function deleteBlackList(data) {
  return axios({
    url: `common/customer_blacklist/deleteBlackList`,
    method: 'post',
    data
  })
}

export function queryCustomerDeadlineList(data) {
  return axios({
    url: `common/customer_deadline/pageList`,
    method: 'post',
    data
  })
}

export function saveCustomerDeadline(data) {
  return axios({
    url: `common/customer_deadline/saveUpdateDeadline`,
    method: 'post',
    data
  })
}

export function deleteCustomerDeadline(data) {
  return axios({
    url: `common/customer_deadline/deleteDeadline`,
    method: 'post',
    data
  })
}

export function queryCustomerRemindList(data) {
  return axios({
    url: `common/customer_remind/pageList`,
    method: 'post',
    data
  })
}
