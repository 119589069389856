// eslint-disable-next-line
import * as loginService from '@/api/login'
// eslint-disable-next-line
import { BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

// 前端路由表
// const constantRouterComponents = {
//   // 基础页面 layout 必须引入
//   'BasicLayout': BasicLayout,
//   'BlankLayout': BlankLayout,
//   'RouteView': RouteView,
//   'PageView': PageView,
//   '403': () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
//   '404': () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
//   '500': () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),

//   // 你需要动态引入的页面组件

//   // form
//   'BasicForm': () => import('@/views/form/BasicForm'),
//   'StepForm': () => import('@/views/form/stepForm/StepForm'),
//   'AdvanceForm': () => import('@/views/form/advancedForm/AdvancedForm'),

//   // list
//   'ProfileBasic': () => import('@/views/profile/basic/Index'),
//   'ProfileAdvanced': () => import('@/views/profile/advanced/Advanced'),

//   // result
//   'ResultSuccess': () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
//   'ResultFail': () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),

//   // exception
//   'Exception403': () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
//   'Exception404': () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
//   'Exception500': () => import(/* webpackChunkName: "fail" */ '@/views/exception/500')

// }

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*', redirect: '/404', hidden: true
}

// 根级菜单
const rootRouter = {
  path: '/',
  name: 'index',
  component: 'BasicLayout',
  redirect: '/wms/business/drop_box_list',
  meta: {
    title: '首页'
  },
  children: []
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (token) => {
  return new Promise((resolve, reject) => {
    loginService.getCurrentUserNav(token).then(res => {
      console.log('res', res)
      sessionStorage.setItem('buttonList', JSON.stringify(res.button_list))
      const menuNav = []
      rootRouter.children = res.menu_list
      if (res.menu_list.length > 0) {
        rootRouter.redirect = res.menu_list[0].path
      }
      menuNav.push(rootRouter)
      const routers = generator(menuNav)
      routers.push(notFoundRouter)
      resolve(routers)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  // console.log(BasicLayout)
  return routerMap.map(item => {
    // eslint-disable-next-line camelcase
    const { title, hidden, hide_children_in_menu, hidden_header_content, target, icon, sub_page, keep_alive } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path,
      // 路由名称，建议唯一
      name: item.name,
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: getLayout(item.component),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        hidden: hidden,
        icon: icon || undefined,
        hiddenHeaderContent: hidden_header_content,
        target: target,
        subPage: sub_page,
        keepAlive: keep_alive
      }
    }
    // 是否设置了隐藏菜单
    // if (show === false) {
    //   currentRouter.hidden = true
    // }
    // 是否设置了隐藏子菜单
    // eslint-disable-next-line camelcase
    if (hide_children_in_menu) {
      // eslint-disable-next-line camelcase
      currentRouter.hideChildrenInMenu = hide_children_in_menu
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

const getLayout = (value) => {
  switch (value) {
    case 'BasicLayout':
      return BasicLayout
    case 'BlankLayout':
      return BlankLayout
    case 'RouteView':
      return RouteView
    case 'PageView':
      return PageView
    default:
      return () => import(`@/views/${value}`)
  }
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
// const listToTree = (list, tree, parentId) => {
//   list.forEach(item => {
//     // 判断是否为父级菜单
//     if (item.parentId === parentId) {
//       const child = {
//         ...item,
//         key: item.key || item.name,
//         children: []
//       }
//       // 迭代 list， 找到当前菜单相符合的所有子菜单
//       listToTree(list, child.children, item.id)
//       // 删掉不存在 children 值的属性
//       if (child.children.length <= 0) {
//         delete child.children
//       }
//       // 加入到树中
//       tree.push(child)
//     }
//   })
// }
