<template>
  <div class="logo">
    <router-link :to="{name:'profile'}">
      <!-- <LogoSvg alt="logo" /> -->
      <img src="~@/assets/logo.png" class="sider-logo" alt="logo">
      <h1 v-if="showTitle">{{ title }}</h1>
    </router-link>
  </div>
</template>

<script>
import LogoSvg from '@/assets/logo.svg?inline'

export default {
  name: 'Logo',
  components: {
    LogoSvg
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  }
}
</script>

<style scoped>
  .sider-logo{
    width: auto!important;
  }
</style>
