import { axios } from '@/utils/request'

export function getSystemPage(type, parameter) {
  return axios({
    url: `sys/${type}/list`,
    method: 'post',
    data: parameter
  })
}

export function createSystemInfo(type, data) {
  return axios({
    url: `sys/${type}/create`,
    method: 'post',
    data
  })
}

export function getSysUserMetaOption(type, data) {
  return axios({
    url: `sys/${type}/option`,
    method: 'get',
    params: data
  })
}

export function saveSystemInfo(type, data) {
return axios({
  url: `sys/${type}/save`,
  method: 'post',
  data
})
}

export function updateSystemInfo(type, data) {
  return axios({
      url: `sys/${type}/update`,
      method: 'post',
      data
  })
}

export function deleteSystemInfo(type, data) {
  return axios({
      url: `sys/${type}/delete`,
      method: 'post',
      data
  })
}

export function getSystemInfoOption(type) {
  return axios({
      url: `sys/${type}/option`,
      method: 'get'
  })
}

export function getResourceTree() {
  return axios({
    url: `sys/resource/tree`,
    method: 'get'
  })
}

export function getMenuTree() {
  return axios({
    url: `sys/menu/list`,
    method: 'get'
  })
}

export function getMenuRoleTree() {
  return axios({
    url: `sys/menu/roleTree`,
    method: 'get'
  })
}

export function editPwd(data) {
  return axios({
    url: `sys/user/editPwd`,
    method: `put`,
    data
  })
}
