<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'

export default {
  mixins: [AppDeviceEnquire],
  data () {
    return {
      locale: zhCN
    }
  },
  mounted () {
    if (window.completeEnterBind) return
    document.addEventListener('keydown', this.enterCallback)
    window.completeEnterBind = true
  },
  destroy() {
    document.removeEventListener('keydown', this.enterCallback);
    window.completeEnterBind = false
  },
  methods: {
    enterCallback(e) {
      if (e.keyCode === 13) {
        e.preventDefault()
        e.stopPropagation()
        let nextItem = this.findNextItem(document.activeElement)
        if (nextItem) {
          if (nextItem.getAttribute('disabled')) { // 禁用组件则跳过
            nextItem = this.findNextItem(nextItem)
          }
          this.GLOBAL.nextItem = nextItem
          nextItem.focus()
        } else {
          // if (e.target.className.includes('ant-calendar-input')) {
          //   this.GLOBAL.nextItem && this.GLOBAL.nextItem.focus()
          // } {
          this.GLOBAL.nextItem && this.GLOBAL.nextItem.focus()
        }
      }
    },
    findNextItem(el) {
      if (!el || (el && el.tagName === 'BODY')) return
      let next = el.nextElementSibling
      if (next) {
        if (next.className.includes('ant-divider')) { // 遇到分割线则继续查找下一个节点
          return this.findNextItem(next)
        } else if (next.tagName === 'TD' || next.querySelector('td')) {
          next = next.tagName === 'TD' ? next : next.querySelector('td')
          return this.findInput(next) || this.findNextItem(next)
        } else if (this.findInput(next) || (next.querySelector('.ant-form-item') && next.querySelector('.ant-row'))) {
          return this.findInput(next) || this.findInput(next.querySelector('.ant-form-item'))
        } else {
          return this.findNextItem(el.parentElement)
        }
      } else {
        return this.findNextItem(el.parentElement)
      }
    },
    findInput(el) {
      let input
      let nextEl = el
      let item
      if (el.querySelector('.ant-form-item')) { // 获取第一个ant-form-item 节点
        nextEl = el.querySelector('.ant-form-item')
      }
      if (nextEl.className.includes('ant-input-group') || nextEl.querySelector('.ant-input-group')) { // 如果遇到ant-input-group组件 则需取下面第一个子节点
        nextEl = nextEl.className.includes('ant-input-group') ? nextEl.children[0] : nextEl.querySelector('.ant-input-group').children[0]
      }
      if (nextEl.tagName === 'INPUT') {
        return nextEl
      } else if (nextEl.classList.contains('ant-select')) {
        item = nextEl
      } else {
        item = nextEl.querySelector('input:not(.ant-select-search__field)') || nextEl.querySelector('.ant-select') || nextEl.querySelector('.ant-calendar-picker-input') ||
           nextEl.querySelector('.ant-switch') || nextEl.querySelector('textarea');
      }
      if (!item) return null
      if (item.className.includes('ant-select') && (item.className.includes('ant-select-combobox') || nextEl.querySelector('.ant-select-selection--multiple'))) {
        input = nextEl.querySelector('.ant-select-search__field')
      } else {
        input = item
      }
      return input;
    }
  }
}
</script>
<style>
  #app {
    height: 100%;
  }
</style>
