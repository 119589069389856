import vPinyin from './vue-py.js';
export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export function getRandomCode(length) {
  if (length > 0) {
     var data = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
     var nums = '';
     for (var i = 0; i < length; i++) {
        var r = parseInt(Math.random() * 61);
        nums += data[r];
     }
     return nums;
  } else {
     return false;
  }
}

export function objDeepCopy(source) {
  if (typeof source === 'object' && source !== null) {
    var sourceCopy = source instanceof Array ? [] : {}
    for (var item in source) {
      sourceCopy[item] = typeof source[item] === 'object' ? objDeepCopy(source[item]) : source[item]
    }
    return sourceCopy
  } else {
    return source
  }
}

export function customizeAssign(source, target) {
  const keys = Object.keys(target)
  for (const key in source) {
    if (keys.includes(key)) {
      source[key] = target[key] === undefined ? source[key] : target[key]
    }
  }
}

export function getFirstLetter(name) {
  const pinyin = vPinyin.chineseToPinYin(name);
  let SX = '';
  for (var i = 0; i < pinyin.length; i++) {
    var c = pinyin.charAt(i);
    if (/^[A-Z]+$/.test(c)) {
      SX += c;
    }
  }
  return SX
}

export function filterOption(input, option) {
  return (
    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      getFirstLetter(option.componentOptions.children[0].text).toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
}

export function numberToChinese(n) {
  switch (n) {
    case '0': return '零'
    case '1': return '壹'
    case '2': return '贰'
    case '3': return '叁'
    case '4': return '肆'
    case '5': return '伍'
    case '6': return '陆'
    case '7': return '柒'
    case '8': return '捌'
    case '9': return '玖'
    default: return ' '
}
}
