import { axios } from '@/utils/request'
export function getList (parameter) {
  return axios({
    url: `mms/getList`,
    method: 'get',
    baseURL: '/mock',
    data: parameter
  })
}

export function getMmsPage(type, params) {
  return axios({
    url: `mms/${type}/list`,
    method: 'get',
    params
  })
}

export function getMmsInfoPage(type, params) {
  return axios({
    url: `mms/${type}/list_business`,
    method: 'get',
    params
  })
}

export function createMmsInfo(type, data) {
    return axios({
      url: `mms/${type}/create`,
      method: 'post',
      data
    })
}

export function saveMmsInfo(type, data) {
  return axios({
    url: `mms/${type}/save`,
    method: 'post',
    data
  })
}

export function approvalSaveMmsInfo(type, data) {
  return axios({
    url: `mms/${type}/approval_save`,
    method: 'post',
    data
  })
}

export function updateMmsInfo(type, data) {
    return axios({
        url: `mms/${type}/update`,
        method: 'post',
        data
    })
}

export function deleteMmsInfo(type, data) {
    return axios({
        url: `mms/${type}/delete`,
        method: 'post',
        data
    })
}

export function removeMmsInfo(type, data) {
  return axios({
      url: `mms/${type}/remove`,
      method: 'post',
      data
  })
}

export function getMmsOptions(type, params) {
  return axios({
    url: `mms/${type}/option`,
    method: 'get',
    params
  })
}

export function contractConfirm(data) {
  return axios({
    url: `mms/lease_info/confirm`,
    method: 'post',
    data
  })
}

export function leaseSubmitAgain(data) {
  return axios({
    url: `mms/lease_info/lease_submit_again`,
    method: 'post',
    data
  })
}

export function contractVerfiy(flag, ids) {
  return axios({
    url: `mms/lease_info/verify?verify=${flag}&ids=${ids}`,
    method: 'post'
  })
}

export function getMmsTenantOptions(type) {
  return axios({
    url: `mms/meta/tenant_info/contact_list`,
    method: 'get'
  })
}

export function getMmsRegisterTenantOptions() {
  return axios({
    url: `mms/rent_register/contact_list`,
    method: 'get'
  })
}

export function getMmsFinancePage(params) {
  return axios({
    url: `mms/finance_center/bill_list`,
    method: 'get',
    params
  })
}

export function applyMmsFinance(data) {
  return axios({
    url: `mms/finance_center/ticket_apply`,
    method: 'post',
    data
  })
}

export function mmsFinanceFunc(type, data) {
  return axios({
    url: `mms/finance_center/${type}`,
    method: 'post',
    data
  })
}

export function exportMmsFinance(params) {
  return axios({
    url: `mms/finance_center/export_data`,
    method: 'get',
    params
  })
}

export function getMmsMetaPage(type, parameter) {
  return axios({
    url: `mms/meta/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function createMmsMetaInfo(type, data) {
  return axios({
    url: `mms/meta/${type}/save`,
    method: 'post',
    data
  })
}

export function updateMmsMetaInfo(type, data) {
  return axios({
      url: `mms/meta/${type}/edit`,
      method: 'put',
      data
  })
}

export function deleteMmsMetaInfo(type, data) {
  return axios({
      url: `mms/meta/${type}/delete`,
      method: 'post',
      data
  })
}

export function getMmsContractPage(type, parameter) {
  return axios({
    url: `mms/lease_info/${type}`,
    method: 'get',
    params: parameter
  })
}

export function editLeaseInfo(parameter) {
  return axios({
    url: `mms/lease_info/edit_lease_info`,
    method: 'put',
    data: parameter
  })
}

export function getMmsBalancePage(type, data) {
  return axios({
    url: `/mms/balance/${type}`,
    method: 'post',
    data
  })
}

// 合同登记页面初始化参数
export function initLeaseInfo() {
  return axios({
    url: `/mms/lease_info/init`,
    method: 'get'
  })
}

// 分页查询合同信息
export function pageLeaseInfo(data) {
  return axios({
    url: `/mms/lease_info/pageLeaseInfo`,
    method: 'post',
    data
  })
}

// 新增或修改合同信息
export function saveOrUpdateLeaseInfo(data) {
  return axios({
    url: `/mms/lease_info/saveOrUpdateLeaseInfo`,
    method: 'post',
    data
  })
}

// 单独编辑合同备注信息
export function modifyLeaseInfoRemark(data) {
  return axios({
    url: `/mms/lease_info/modifyLeaseInfoRemark`,
    method: 'post',
    data
  })
}

// 单独编辑合同税务类型
export function modifyLeaseInfoTaxType(data) {
  return axios({
    url: `/mms/lease_info/modifyLeaseInfoTaxType`,
    method: 'post',
    data
  })
}

// 删除合同信息
export function deleteLeaseInfo(data) {
  return axios({
    url: `/mms/lease_info/deleteLeaseInfo`,
    method: 'post',
    data
  })
}

// 导出合同信息
export function exportLeaseInfo(data) {
  return axios({
    url: `/mms/lease_info/exportLeaseInfo`,
    method: 'post',
    data
  })
}

// 选择指定合同信息、上传合同文件建立关联关系
export function uploadFileByLeaseInfo(data) {
  return axios({
    url: `/mms/lease_info/uploadFileByLeaseInfo`,
    method: 'post',
    data
  })
}

// 选择指定合同信息、上传合同文件建立关联关系
export function deleteContractFile(data) {
  return axios({
    url: `/mms/lease_info/deleteContractFile`,
    method: 'post',
    data
  })
}

// 分页查询付款类型信息
export function pageMmsBusinessTypeInfo(data) {
  return axios({
    url: `/mms/meta/business_type_info/pageMmsBusinessTypeInfo`,
    method: 'post',
    data
  })
}

// 新增或修改付款类型信息
export function saveOrUpdateMmsBusinessTypeInfo(data) {
  return axios({
    url: `/mms/meta/business_type_info/saveOrUpdateMmsBusinessTypeInfo`,
    method: 'post',
    data
  })
}

// 删除付款类型
export function deleteMmsBusinessTypeInfo(data) {
  return axios({
    url: `/mms/meta/business_type_info/deleteMmsBusinessTypeInfo`,
    method: 'post',
    data
  })
}

// 分页查询合同类型信息
export function pageContractTypeInfo(data) {
  return axios({
    url: `/mms/meta/contract_type_info/pageContractTypeInfo`,
    method: 'post',
    data
  })
}

// 新增或修改合同类型信息
export function saveOrUpdateMmsContractTypeInfo(data) {
  return axios({
    url: `/mms/meta/contract_type_info/saveOrUpdateMmsContractTypeInfo`,
    method: 'post',
    data
  })
}

// 删除合同类型
export function deleteMmsContractTypeInfo(data) {
  return axios({
    url: `/mms/meta/contract_type_info/deleteMmsContractTypeInfo`,
    method: 'post',
    data
  })
}

export function initMmsAdjust() {
  return axios({
    url: `mms/adjust-order/init`,
    method: 'get'
  })
}

export function pageListAdjust(data) {
  return axios({
    url: `mms/adjust-order/pageList`,
    method: 'post',
    data
  })
}

export function submitApplyAdjust(data) {
  return axios({
    url: `mms/adjust-order/submitApply`,
    method: 'post',
    data
  })
}

export function deleteAdjustOrder(data) {
  return axios({
    url: `mms/adjust-order/deleteAdjustOrder`,
    method: 'post',
    data
  })
}

export function saveAdjustOrder(data) {
  return axios({
    url: `mms/adjust-order/saveAdjust`,
    method: 'post',
    data
  })
}

export function getMmsDetailBySerialNo(params) {
  return axios({
    url: `mms/adjust-order/queryDetailBySerialNum`,
    method: 'get',
    params
  })
}

export function queryCompositePages(data) {
  return axios({
    url: `mms/composite_order/pageList`,
    method: 'post',
    data
  })
}

export function saveUpdateCompositeOrder(data) {
  return axios({
    url: `mms/composite_order/saveOrder`,
    method: 'post',
    data
  })
}

export function removeCompositeOrder(data) {
  return axios({
    url: `mms/composite_order/removeOrder`,
    method: 'post',
    data
  })
}

export function getCompositeDetailById(data) {
  return axios({
    url: `mms/composite_order/getDetailById`,
    method: 'post',
    data
  })
}

export function queryCompositeFeePages(data) {
  return axios({
    url: `mms/composite_order/queryFeeByDepartment`,
    method: 'post',
    data
  })
}

export function checkCompositeFees(data) {
  return axios({
    url: `mms/composite_order/checkFee`,
    method: 'post',
    data
  })
}

export function mmsBusinessAuditPayment(data) {
  return axios({
    url: `mms/composite_order/business_audit`,
    method: 'post',
    data
  })
}

export function listPagePaymentList(data) {
  return axios({
    url: `mms/composite_finance/pagePaymentList`,
    method: 'post',
    data
  })
}

export function deletePayment(data) {
  return axios({
    url: `mms/composite_finance/deletePayment`,
    method: 'post',
    data
  })
}
