<script>
	const queryColSpan = {
    xs: 24,
    sm: 15,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 6
  }
  const queryColSpanMulti = (n = 1) => {
    return {
      xs: 24,
      sm: 15 * n,
      md: 12 * n,
      lg: 8 * n,
      xl: 8 * n,
      xxl: 6 * n
    }
  }
  const queryRowGutter = 24
  /**
   * 所有部门信息map结构
   */
  const departmentMaps = {
    1: { value: 1, name: '仓储部' },
    2: { value: 2, name: '国贸部' },
    3: { value: 3, name: '总经办' },
    4: { value: 4, name: '查验部' },
    5: { value: 5, name: '财商部' },
    6: { value: 6, name: '堆场' },
    7: { value: 7, name: '客服部' },
    8: { value: 8, name: '进销存' },
    9: { value: 9, name: '综合部' }
  }
  const invoiceDepartmentMaps = {
    2: { value: 2, name: '国贸部' },
    3: { value: 3, name: '总经办' },
    7: { value: 7, name: '客服部' },
    8: { value: 8, name: '进销存' }
  }
  const wmsInStatusMaps = {
    1: { value: 1, name: '已换票' },
    2: { value: 2, name: '卸货中' },
    3: { value: 3, name: '卸货完成' },
    4: { value: 4, name: '出库中' },
    5: { value: 5, name: '出库完成' },
    6: { value: 6, name: '已结算' },
    9: { value: 9, name: '未出场' },
    10: { value: 10, name: '出场中' },
    11: { value: 11, name: '已出场' }
  }
  const feeStatusMaps = {
    1: { value: 1, name: '待审核' },
    2: { value: 2, name: '业务已审核' },
    3: { value: 3, name: '商务待审核' },
    4: { value: 4, name: '商务审核被驳回' },
    5: { value: 5, name: '待开票' },
    6: { value: 6, name: '已开票' },
    9: { value: 9, name: '商务已审核(待付款)' },
    13: { value: 13, name: '已付款' }
  }
  const finFeeStatusMaps = {
    3: { value: 3, name: '商务待审核' },
    4: { value: 4, name: '商务审核被驳回' },
    5: { value: 5, name: '待开票' },
    6: { value: 6, name: '已开票' }
  }
  const finDepartment = {
    1: { value: 1, name: '仓储部' },
    2: { value: 2, name: '国贸部' },
    3: { value: 3, name: '总经办' },
    7: { value: 7, name: '客服部' },
    8: { value: 8, name: '进销存' },
    6: { value: 6, name: '堆场' },
    5: { value: 5, name: '财商部' }
  }
  const finPayFeeStatusMaps = {
    3: { value: 3, name: '商务待审核' },
    4: { value: 4, name: '商务审核被驳回' },
    9: { value: 9, name: '商务已审核(待付款)' },
    13: { value: 13, name: '已付款' }
  }
  const finPayDepartment = {
    1: { value: 1, name: '仓储部' },
    2: { value: 2, name: '国贸部' },
    3: { value: 3, name: '总经办' },
    7: { value: 7, name: '客服部' },
    8: { value: 8, name: '进销存' },
    6: { value: 6, name: '堆场' },
    9: { value: 9, name: '综合部' }
  }
  const wmsOrderStatus = {
    1: { value: 1, name: '正常' },
    2: { value: 2, name: '退货' },
    3: { value: 3, name: '退款' },
    4: { value: 4, name: '部分退货' },
    5: { value: 5, name: '部分退款' }
  }
  const invoiceTypeOps = {
    0: { value: 0, name: '未知' },
    1: { value: 1, name: '专用发票' },
    2: { value: 2, name: '普通发票' },
    3: { value: 3, name: '免税发票' },
    4: { value: 4, name: 'TMS奥货' },
    5: { value: 5, name: '作废票' }
  }
  export default {
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    queryColSpan,
    queryColSpanMulti,
    queryRowGutter,
    departmentMaps,
    wmsInStatusMaps,
    feeStatusMaps,
    finFeeStatusMaps,
    finPayFeeStatusMaps,
    finDepartment,
    finPayDepartment,
    nextItem: null,
    invoiceDepartmentMaps,
    wmsOrderStatus,
    invoiceTypeOps
  }
</script>
