<template>
  <div>
    <p v-if="title" class="table-title">{{ title }}</p>
    <p v-if="operate.alldel"><a-button size="small" type="danger" @click="handleDeleteAll">删除</a-button></p>
    <a-table bordered :dataSource="data"
             :scroll="scroll ? { x: scrollX } : {}"
             :components="components"
             :columns="tableColumns" :pagination="false"
             :rowSelection="operate.alldel ? { selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange } : null"
             rowKey="_index"
             size="small" class="edit-table">
      <template :slot="field.dataIndex" slot-scope="text, record, index" v-for="(field, index1) in columns">
        <a-select placeholder="" v-if="field.extra && field.extra.type === 'select'"
                  :value="text" :key="'k' + index1 + index"
                  showSearch :filter-option="filterOption"
                  :ref="field.dataIndex + index" :mode="field.extra.mode || 'default'"
                  :disabled="field.extra && field.extra.disabled ? field.extra.disabled(record) : false"
                  class="full-width" @change="e => handleChange(e, record.id, field.dataIndex, index)">
          <template v-if="field.extra && field.extra.flexible">
            <a-select-option v-for="d in tableOptions[field.dataIndex + index]" :key="d.value">{{ d.name }}</a-select-option>
          </template>
          <template v-else>
            <a-select-option v-for="d in tableOptions[field.dataIndex]" :key="d.value">{{ d.name }}</a-select-option>
          </template>
        </a-select>
        <a-auto-complete v-else-if="field.extra && field.extra.type === 'autocomplete'"
                         :value="text" :key="'k' + index1 + index"
                         :disabled="field.extra && field.extra.disabled ? field.extra.disabled(record) : false"
                         class="full-width" @change="e => handleChange(e, record.id, field.dataIndex, index)"
                         :filterOption="handleAutoSearch">
          <template slot="dataSource">
            <a-select-option v-for="op in tableOptions[field.dataIndex]" :key="op.name">{{ op.name }}</a-select-option>
          </template>
        </a-auto-complete>
        <a-switch checkedChildren="是" unCheckedChildren="否"
                  :key="'k' + index1 + index" v-else-if="field.extra && field.extra.type === 'switch'"
                  :checked="text" @change="e => handleChange(e, record.id, field.dataIndex, index)"/>
        <a-input-number :value="text" :key="'k' + index1 + index"
                        class="full-width"
                        :disabled="field.extra && field.extra.disabled ? field.extra.disabled(record) : false"
                        v-else-if="field.extra && field.extra.type === 'number'" @change="e => handleChange(e, record.id, field.dataIndex, index)"/>
        <a-date-picker :key="'k' + index1 + index" v-else-if="field.extra && field.extra.type === 'date'"
                       :disabled="field.extra && field.extra.disabled ? field.extra.disabled(record) : false"
                       :format="field.extra.showTime ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD'"
                       :value="text ? moment(text, field.extra.showTime ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD') : null"
                       :showTime="field.extra.showTime ? {defaultValue: moment(text, 'YYYY/MM/DD HH:mm:ss')} : false"
                       @change="(e, value )=> handleChange(value, record.id, field.dataIndex, index)"
        />
        <span :key="'k' + index1 + index" v-else-if="field.extra && field.extra.type === 'text'">{{ text }}</span>
        <a-input :value="text" :key="'k' + index1 + index"
                 v-on="field.extra && field.extra.events || null"
                 :disabled="field.extra && field.extra.disabled ? field.extra.disabled(record) : false"
                 v-else @change="e => handleChange(e.target.value, record.id, field.dataIndex, index)"/>
      </template>

      <template slot="operation" slot-scope="text, record, index">
        <a-popconfirm
          v-if="data.length"
          title="确认删除?"
          :disabled="record.disabled"
          @confirm="() => onDelete(index)"
        >
          <a href="javascript:;" :disabled="record.disabled"><a-icon type="delete" :style="{ fontSize: '18px', color: '#ff4d4f' }"/></a>
        </a-popconfirm>
      </template>

    </a-table>
    <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed"
              icon="plus" v-if="operate.add"
              :disabled="disabled" @click="handleAdd()">新增</a-button>
  </div>
</template>

<script>
import Vue from 'vue';
import VueDraggableResizable from 'vue-draggable-resizable'
import { getRandomCode, filterOption } from '@/utils/util'
import moment from 'moment';
Vue.component('vue-draggable-resizable', VueDraggableResizable)
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    operate: {
      type: Object,
      default: function() {
        return {
          add: true,
          del: true,
          alldel: false // 全选
        }
      }
    },
    columns: {
      type: Array,
      default: function() {
        // {
        //   title: '',
        //   dataIndex: '',
        //   extra: {
        //     type: 'select',
        //     flexible: true, 说明下拉框内容是灵活变动的
        //     options: [],
        //     func: func,
        //     params: [],
        //     extraFunc: func,
        //     disabled: func
        //     defaultValue: val
        //   }
        // }
        return []
      }
    },
    scroll: { // 是否加载滚动条
      type: Boolean,
      default: false
    },
    disabled: { // 是否禁用
      type: Boolean,
      default: false
    },
    sourceData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    this.components = {
      header: {
        cell: (h, props, children) => {
          const draggingMap = {};
          this.tableColumns.forEach(col => {
            draggingMap[col.dataIndex] = col.width;
          });
          const draggingState = Vue.observable(draggingMap);
          let thDom = null;
          const { key, ...restProps } = props;
          const col = this.tableColumns.find((col, index) => {
            const k = col.dataIndex || col.key;
            return k === key;
          });
          if (!col || !col.width) {
            return h('th', { ...restProps }, [...children])
          }
          const onDrag = x => {
            draggingState[key] = 0;
            col.width = Math.max(x, 1);
          };

          const onDragstop = () => {
            draggingState[key] = thDom.getBoundingClientRect().width;
          };
          return (
            <th {...restProps} v-ant-ref={r => (thDom = r)} width={col.width} class="resize-table-th">
              {children}
              <vue-draggable-resizable
                key={col.dataIndex || col.key}
                class='table-draggable-handle'
                w={10}
                x={draggingState[key] || col.width}
                z={1}
                axis='x'
                draggable={true}
                resizable={false}
                onDragging={onDrag}
                onDragstop={onDragstop}
              ></vue-draggable-resizable>
            </th>
          );
        }
      }
    }
    return {
      scrollX: 800,
      selectedRowKeys: [],
      tableColumns: [],
      columnsMap: {},
      data: [],
      dataForm: null,
      tableOptions: {} // 表格里的各种下拉框选项数据集合
    }
  },
  watch: {
    sourceData: function(v) {
      this.data = [ ...v ].map(d => {
        d['_index'] = getRandomCode(8)
        return d
      })
    },
    columns: function(c) {
      this.columns.forEach(v => {
        if (v.extra && (v.extra.type === 'select' || v.extra.type === 'autocomplete')) {
          if (!v.extra.func) {
            this.$set(this.tableOptions, v.dataIndex, v.extra.options || [])
          }
        }
      })
    }
  },
  created() {
    this.data = [ ...this.sourceData ].map(d => {
      d['_index'] = getRandomCode(8)
      return d
    })
    let x = 0
    this.columns.forEach(v => {
      x = x + v.width || 0
      this.columnsMap[v.dataIndex] = v
      const hidden = v.extra && v.extra.hidden
      if (v.extra && (v.extra.type === 'select' || v.extra.type === 'autocomplete')) {
        if (v.extra.func) {
          const func = v.extra.func
          func(...v.extra.params).then(r => {
            this.$set(this.tableOptions, v.dataIndex, r)
          })
        } else {
          this.$set(this.tableOptions, v.dataIndex, v.extra.options || [])
        }
      }
      const temp = { ...v }
      temp['scopedSlots'] = { customRender: temp.dataIndex, ...v.scopedSlots }
      delete temp['extra']
      if (!hidden) {
        this.tableColumns.push(temp)
      }
    })
    this.scrollX = x
    if (this.operate.del) {
      this.tableColumns.splice(0, 0, {
            title: '操作',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
            width: 50
          })
    }
  },
  methods: {
    getRandomCode,
    filterOption,
    moment,
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    onDelete(index) {
      this.data.splice(index, 1)
      this.$emit('getTableDate', this.data)
    },
    handleDeleteAll() {
      const _data = [...this.data];
      this.data = _data.filter(item => !this.selectedRowKeys.includes(item._index));
      this.$emit('getTableDate', this.data)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      console.log(this.selectedRowKeys, 888)
      this.selectedRowKeys = selectedRowKeys
    },
    handleAdd() {
      if (!this.dataForm) {
        this.dataForm = {}
        this.columns.map(c => {
          const defaultVal = c.extra && c.extra.defaultValue
          switch (!c.extra || c.extra.type) {
            case 'number':
            case 'date':
              this.$set(this.dataForm, c.dataIndex, defaultVal || null)
              break;
            case 'select':
              if (c.extra.mode === 'multiple') {
                this.$set(this.dataForm, c.dataIndex, defaultVal || [])
              } else {
                this.$set(this.dataForm, c.dataIndex, defaultVal || null)
              }
              break
            case 'switch':
              this.$set(this.dataForm, c.dataIndex, defaultVal || true)
              break;
            default:
              this.$set(this.dataForm, c.dataIndex, defaultVal || '')
              break;
          }
        })
      }
      this.$set(this.dataForm, '_index', getRandomCode(8))
      console.log(this.data, this.dataForm)
      this.data.push({ ...this.dataForm })
      this.$emit('getTableDate', this.data)
    },
    handleChange(value, key, column, index) {
      const newData = this.data // [...this.data]
      const target = newData[index] // newData.find(item => key === item.id)
      if (target) {
        target[column] = value
        // this.$set(target, column, value)
        const columnConfig = this.columnsMap[column]
        if (columnConfig && columnConfig.extra && columnConfig.extra.extraFunc) {
          const response = columnConfig.extra.extraFunc(value, newData, target, index)
          if (response instanceof Promise) {
            response.then(res => {
              this.data = res
            })
          } else {
            this.data = response
          }
        } else {
          this.data = newData
        }
      }
      const changeData = {
        'value': value,
        'column': column,
        'index': index
      }
      this.$emit('getChangeData', changeData)
    },
    getResizeableTitle(h, props, children) {
    }
  }
  // render() {
  //   const table = (
  //     <a-table bordered dataSource={this.data}
  //            scroll={this.scroll ? { x: this.scrollX } : {}}
  //            components={this.components}
  //            columns={this.tableColumns} pagination={false}
  //            rowSelection={this.operate.alldel ? { selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange } : null}
  //            rowKey="_index"
  //            size="small" class="edit-table">
  //       {
  //         this.columns.map((field, index1) => {
  //           if (field.extra) {
  //             switch (field.extra.type) {
  //               case 'select':
  //                 return (
  //                   <template slot={field.dataIndex} slot-scope="text, record, index">
  //                     <a-select value={text} key={'k' + index1 + index}
  //                               showSearch filter-option={this.filterOption}
  //                               ref={field.dataIndex + index} mode={field.extra.mode || 'default'}
  //                               disabled={field.extra && field.extra.disabled ? field.extra.disabled(record) : false}
  //                               class="full-width" onChange={e => this.handleChange(e, record.id, field.dataIndex, index)}>
  //                       {
  //                         field.extra.flexible ? <a-select-option v-for="d in tableOptions[field.dataIndex + index]" key={d.value}>{d.name}</a-select-option>
  //                           : <a-select-option v-for="d in tableOptions[field.dataIndex]" key={d.value}>{d.name}</a-select-option>
  //                       }
  //                     </a-select>
  //                   </template>
  //                 )
  //               default:
  //                 break;
  //             }
  //           }
  //         })
  //       }
  //     </a-table>
  //   )
  //   return (
  //     <div>
  //       {
  //         this.title ? <p class="table-title">{ this.title }</p> : null
  //       }
  //       {
  //         this.operate.alldel ? <p><a-button size="small" type="danger" onClick={this.handleDeleteAll}>删除</a-button></p> : null
  //       }
  //       { table }
  //       {
  //         this.operate.add ? <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed"
  //             icon="plus" disabled={this.disabled} onClick={ this.handleAdd }>新增</a-button> : null
  //       }
  //     </div>
  //   )
  // }
}
</script>

<style>
  .edit-table .ant-empty-normal {
    margin: 0;
  }
  .table-title {
    margin-bottom: 5px;
    color: #1890ff;
    font-weight: 600
  }
  .resize-table-th {
    position: relative;
  }
  .resize-table-th .table-draggable-handle {
    position: absolute;
    height: 100% !important;
    bottom: 0;
    /* left: 0; */
    /* left: auto !important; */
    right: 0;
    cursor: col-resize;
    touch-action: none;
    transform: none!important;
  }
</style>
