<template>
  <div>
    <template v-for="(groupItem, index) in targetValues">
      <a-input-group compact :key="index" :class="['field-item', className]">
        <template v-for="(item, key) in fields">
          <a-date-picker showTime v-model="groupItem[item.name]"
                         :style="item.style" :placeholder="item.placeholder"
                         :key="item.name + index + key" v-if="item.type === 'date'"/>
          <a-select showSearch :style="item.style"
                    v-model="groupItem[item.name]"
                    :key="item.name + index + key" v-else-if="item.type === 'select'">
            <a-select-option v-for="op in item.options" :key="op.value">{{ op.name }}</a-select-option>
          </a-select>
          <a-input :style="item.style" :placeholder="item.placeholder"
                   v-model="groupItem[item.name]"
                   :key="item.name + index + key" v-else/>
        </template>
        <a-icon type="close-circle" theme="filled"
                class="field-item_del" v-if="index > 0"
                @click="delItem(index)"/>
        <a-button type="primary" v-if="index === (targetValues.length - 1)"
                  style="width: 60px;margin-left: 10px" @click="createItem()">更多</a-button>
      </a-input-group>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },
    values: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      targetValues: [],
      itemFields: {}
    }
  },
  watch: {
    values: function (val) {
      this.targetValues = val
    }
  },
  created() {
    if (this.targetValues && this.targetValues.length === 0) {
      this.createItem()
    }
  },
  methods: {
    createItem() { // 增加更多箱型
      const data = {}
      this.fields.forEach(i => {
        switch (i.type) {
          case 'select':
          case 'date':
            data[i.name] = null
            break;
          default:
            data[i.name] = ''
            break;
        }
      })
      this.targetValues.push(data)
      this.$emit('update:values', this.targetValues)
    },
    delItem(index) {
      this.targetValues.splice(index, 1)
      this.$emit('update:values', this.targetValues)
    }
  }
}
</script>

<style scoped>
  .field-item{
    position: relative;
    width:200px;
    margin-right: 10px;
    margin-top: 10px;
    color: red;
  }
  .field-item:last-child{
    width: 280px;
  }
  .field-item_del{
    position: absolute;
    top: -5px;
    left: 190px;
    color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
  .field-item_del:hover, .send-item_del:hover{
    color: #1890ff;
  }
</style>
