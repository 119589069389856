import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import modal from 'ant-design-vue/es/modal'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  timeout: 60000 // 请求超时时间
})

const err = (error) => {
  console.log(error, 124)
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  config.headers['product'] = 'Manage'
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['X-TOKEN'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  const res = response.data
  if (res.code !== 200 && res.code) {
    notification['error']({
      message: '错误',
      description: res.message,
      duration: 4
    })
    if (res.code === 401 && location.hash.indexOf('login') < 0) {
      let secondsToGo = 5
      const modalIns = modal.error({
        title: '警告',
        content: `系统将在 ${secondsToGo} 秒后退出登录.`,
        onOk() {
          store.dispatch('Logout').then(() => {
            setTimeout(() => {
              window.location.reload() // 为了重新实例化vue-router对象 避免bug
            }, 1500)
          })
        }
      })
      const interval = setInterval(() => {
        secondsToGo -= 1
        modalIns.update({
          content: `系统将在 ${secondsToGo} 秒后退出登录.`
        })
      }, 1000)
      setTimeout(() => {
        clearInterval(interval)
        modalIns.destroy()
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }, secondsToGo * 1000)
    }
    return Promise.reject(new Error(res.message))
  } else {
    if (response.data instanceof Blob) {
      return response
    } else {
      return res.result
    }
  }
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
