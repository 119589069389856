<template>
  <div class="user-wrapper">
    <div class="content-box">
      <a href="https://pro.loacg.com/docs/getting-started" target="_blank">
        <span class="action">
          <a-icon type="question-circle-o"></a-icon>
        </span>
      </a>
      <notice-icon class="action"/>
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="small" :src="avatar"/>
          <span>{{ nickname }}</span>
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
          <a-menu-item key="2">
            <a href="javascript:;" @click="handleEditPwd">
              <a-icon type="logout"/>
              <span>修改密码</span>
            </a>
          </a-menu-item>
          <a-menu-item key="3">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout"/>
              <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>

      <!-- 修改密码框 -->
      <a-modal
        title="修改密码"
        :width="800"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="visible = false"
        cancelText="关闭">
        <a-form-model :model="form" :rules="rules" ref="form">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="旧密码" prop="old_password">
            <a-input type="password" placeholder="请输入旧密码" v-model="form.old_password" />
          </a-form-model-item>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="新密码" prop="new_password">
            <a-input type="password" placeholder="请输入新密码" v-model="form.new_password" />
          </a-form-model-item>
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="确认新密码" prop="confirm_password">
            <a-input type="password" placeholder="请确认新密码" v-model="form.confirm_password" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import { mapActions, mapGetters } from 'vuex'
import { editPwd } from '@/api/system'

export default {
  name: 'UserMenu',
  components: {
    NoticeIcon
  },
  computed: {
    ...mapGetters(['nickname', 'avatar'])
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      visible: false,
      confirmLoading: false,
      form: {
        // 旧密码
        old_password: '',
        // 新密码
        new_password: '',
        // 确认新密码
        confirm_password: ''
      },
      rules: {
        old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        new_password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        confirm_password: [{ required: true, message: '请确认新密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapActions(['Logout']),
    handleLogout () {
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk: () => {
          return this.Logout({}).then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 16)
          }).catch(err => {
            this.$message.error({
              title: '错误',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    },
    handleEditPwd() {
      this.visible = true;
    },
    handleOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          editPwd(this.form).then(_ => {
            this.visible = false;
            this.$notification['success']({
              message: '提示',
              description: `密码修改成功`
            });
            this.Logout({}).then(() => {
              setTimeout(() => {
                window.location.reload()
              }, 16)
            }).catch(err => {
              this.$message.error({
                title: '错误',
                description: err.message
              })
            });
          }).finally(_ => {
            this.confirmLoading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>
