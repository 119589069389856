<template>
  <div>
    <template v-for="(box, index) in targetValues">
      <a-input-group compact :key="index" class="field-item box-item">
        <a-select
          showSearch :filter-option="filterOption"
          placeholder="箱型"
          style="width: 90px"
          v-model="box[replaceFields.boxType]"
          :allowClear="true">
          <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
        </a-select>
        <a-input v-model="box[replaceFields.boxNum]" style="width: 120px" placeholder="箱号"/>
        <a-input-number v-model="box[replaceFields.num]" style="width: 100px" placeholder="箱量"/>
        <a-icon type="close-circle" theme="filled"
                class="field-item_del" v-if="index > 0"
                @click="delItem(index)"/>
        <a-button type="primary" v-if="index === (targetValues.length - 1)"
                  style="width: 60px;margin-left: 10px" @click="createItem('box_info')">更多</a-button>
      </a-input-group>
    </template>
  </div>
</template>

<script>
import { getCommonOptions } from '@/api/common'
import { filterOption } from '@/utils/util'
export default {
  props: {
    field: {
      type: Array,
      default: () => {
        return []
      }
    },
    replaceFields: {
      type: Object,
      default: () => {
        return {
          boxType: 'id', // 箱型
          boxNum: 'box_num', // 箱号
          num: 'num' // 箱量
        }
      }
    }
  },
  data() {
    return {
      boxOps: [],
      targetValues: []
    }
  },
  watch: {
    field: function (val) {
      this.targetValues = val
    }
  },
  created() {
    this.targetValues = this.field
    getCommonOptions('box_info').then(v => {
      this.boxOps = v
    })
  },
  methods: {
    filterOption,
    createItem(field) { // 增加更多箱型
      const props = { ...this.replaceFields }
      const data = {}
      for (const k in props) {
        switch (k) {
          case 'boxType':
            data[props[k]] = null
            break;
          case 'num':
            data[props[k]] = null
            break;
          default:
            data[props[k]] = ''
            break;
        }
      }
      this.targetValues.push(data)
    },
    delItem(index) {
      this.targetValues.splice(index, 1)
    }
  }
}
</script>

<style scoped>
  .field-item{
    position: relative;
    width:200px;
    margin-right: 10px;
    margin-top: 10px;
    color: red;
  }
  .field-item:last-child{
    width: 280px;
  }
  .field-item_del{
    position: absolute;
    top: -5px;
    left: 190px;
    color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
  .field-item_del:hover, .send-item_del:hover{
    color: #1890ff;
  }
  .box-item{
    width:310px;
  }
  .box-item:last-child{
    width: 410px;
  }
  .box-item  .field-item_del{
    left: 300px;
  }
</style>
